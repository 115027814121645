import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ReactNode } from 'react';

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  display: boolean;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
  icon?: ReactNode;
}

interface CustomTableProps<T> {
  columns: readonly Column[];
  rows: T[] | undefined;
}

export const CustomTable = <T extends Record<string, unknown>>({
  columns,
  rows,
}: CustomTableProps<T>) => {
  return (
    <TableContainer className='custom-table-container'>
      <Table stickyHeader className='custom-table-content'>
        <TableHead className='custom-table-header'>
          <TableRow className='custom-table-header-row'>
            {columns.map((column) => (
              <TableCell
                className='custom-table-header-cell'
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className='custom-table-body'>
          {rows?.map((row) => {
            return (
              <TableRow role='checkbox' tabIndex={-1} className='custom-table-body-row'>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={
                        column.icon ? 'custom-table-body-cell-icon' : 'custom-table-body-cell'
                      }
                    >
                      <div className='table-cell-box'>
                        <span>{column.icon} </span>
                        <span className='value'>{value as ReactNode}</span>
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
