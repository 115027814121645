import { css } from '@emotion/react';

export const headerStyle = css`
  color: #183d4c;
  cursor: pointer;
  .title-content {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .account-icon {
      margin-right: 10px;
    }

    .user-info {
      padding-top: 7px;
      span {
        font-size: 18px;
        font-family: 'Questrial';
      }
      .user-email {
        font-size: 10px;
        line-height: 1px;
        color: #00aad7;
      }
    }
  }

  .popper {
    top: 15px !important;
    text-align: center;

    .popper-content {
      border: solid #f2f2f2;
      padding: 8px;
      background-color: transparent;

      span {
        font-size: 18px;
        font-family: 'Questrial';
      }
    }
  }
`;
