import { StructureValidationState } from '@/components/core/common/helpers';

export const structureListTabs = [
  {
    title: 'manager_app_validated_structures',
    status: StructureValidationState.accepted,
    error: 'manager_app_no_validated_structures',
  },
  {
    title: 'manager_app_waiting_structures',
    status: StructureValidationState.waiting,
    error: 'manager_app_no_waiting_structures',
  },
];
