import { Grid, ListItemText, MenuItem, Paper, TextField, Typography } from '@mui/material';
import { KonvaEventObject } from 'konva/lib/Node';
import React, { useState } from 'react';
import { Layer, Stage } from 'react-konva';

import EditQRCodePopper from '@/views/poster/components/edit-qrcode-popper';
import EditTextPopper from '@/views/poster/components/edit-text-popper';
import ImageUploader from '@/views/poster/components/image-uploader';
import ImagesList from '@/views/poster/components/images-list';
import TemplateDialog from '@/views/poster/components/template-dialog';
import TextItemEditor from '@/views/poster/components/text-item-editor';
import URLImage from '@/views/poster/components/url-image';
import usePoster from '@/views/poster/poster.hooks';

const Poster: React.FC = () => {
  const {
    imageURL,
    setImageURL,
    scale,
    stageSize,
    stageRef,
    texts,
    setTexts,
    images,
    setImages,
    newText,
    setNewText,
    newFontSize,
    setNewFontSize,
    qrCodeContent,
    setQrCodeContent,
    qrCodeColor,
    setQrCodeColor,
    handleSetStageSize,
    addText,
    updateTextColor,
    updateTextContent,
    updateTextFontSize,
    updateTextFontWeight,
    addQRCode,
    addCommunityData,
    downloadStageAsImage,
    deleteText,
    deleteImage,
    imageName,
    setImageName,
    uploadLoading,
    saveImage,
    loadedImages,
    setLoadedImages,
    addedCommunityData,
  } = usePoster();

  const [anchorElText, setAnchorElText] = useState<null | HTMLElement>(null);
  const [anchorElQRCode, setAnchorElQRCode] = useState<null | HTMLElement>(null);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [openTemplateDialog, setOpenTemplateDialog] = useState<boolean>(false);

  const handleClickText = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElText(anchorElText ? null : event.currentTarget);
    if (anchorElQRCode) setAnchorElQRCode(null);
  };

  const handleClickQRCode = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElQRCode(anchorElQRCode ? null : event.currentTarget);
    if (anchorElText) setAnchorElText(null);
  };

  const checkDeselect = (e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>) => {
    if (!e.target.attrs.name) {
      setSelectedId(null);
    }
  };

  const handleClickOpen = () => {
    setOpenTemplateDialog(true);
  };

  const handleClose = () => {
    setOpenTemplateDialog(false);
  };

  return (
    <Grid container spacing={6}>
      <Grid item md={4} height='80vh' overflow='auto'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '10px',
            paddingBottom: '20px',
            paddingLeft: '10px',
            paddingRight: '10px',
          }}
        >
          <Typography variant='h6'>Nom de l'affiche</Typography>
          <TextField
            value={imageName}
            placeholder="Entrez le nom de l'affiche"
            onChange={(e) => setImageName(e.target.value)}
            size='small'
            variant='standard'
            fullWidth
          />
        </div>
        <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          <Paper sx={{ maxWidth: '100%' }}>
            <ImageUploader
              setImageURL={setImageURL}
              setImages={setImages}
              images={images}
              imageURL={imageURL}
              handleClickOpen={handleClickOpen}
            />
            <TemplateDialog
              open={openTemplateDialog}
              handleClose={handleClose}
              setImageURL={setImageURL}
            />
            {imageURL && (
              <>
                <MenuItem
                  sx={{ cursor: 'pointer' }}
                  disabled={addedCommunityData}
                  onClick={addCommunityData}
                >
                  <ListItemText>
                    Appliquer les données <br /> de la communauté
                  </ListItemText>
                </MenuItem>
                <MenuItem sx={{ cursor: 'pointer' }} onClick={handleClickText}>
                  <ListItemText>Ajouter un texte</ListItemText>
                </MenuItem>
                <MenuItem sx={{ cursor: 'pointer' }} onClick={handleClickQRCode}>
                  <ListItemText>Ajouter un QR Code</ListItemText>
                </MenuItem>
                <MenuItem
                  sx={{ cursor: 'pointer' }}
                  disabled={!imageName}
                  onClick={downloadStageAsImage}
                >
                  <ListItemText>Télécharger l'image</ListItemText>
                </MenuItem>
                <MenuItem
                  sx={{ cursor: 'pointer' }}
                  disabled={!imageName || uploadLoading}
                  onClick={saveImage}
                >
                  <ListItemText>Enregistrer l'image</ListItemText>
                </MenuItem>
              </>
            )}
          </Paper>
        </div>
        <EditTextPopper
          mode='add'
          anchorEl={anchorElText}
          setAnchorEl={setAnchorElText}
          newText={newText}
          setNewText={setNewText}
          newFontSize={newFontSize}
          setNewFontSize={setNewFontSize}
          addText={addText}
        />
        <EditQRCodePopper
          anchorEl={anchorElQRCode}
          setAnchorEl={setAnchorElQRCode}
          qrCodeContent={qrCodeContent}
          setQrCodeContent={setQrCodeContent}
          qrCodeColor={qrCodeColor}
          setQrCodeColor={setQrCodeColor}
          addQRCode={addQRCode}
        />
        <TextItemEditor
          texts={texts}
          updateTextColor={updateTextColor}
          updateTextContent={updateTextContent}
          updateTextFontSize={updateTextFontSize}
          updateTextFontWeight={updateTextFontWeight}
          deleteText={deleteText}
        />
        <ImagesList images={images} deleteImage={deleteImage} />
      </Grid>
      <Grid item md={8} bgcolor={imageURL ? 'black' : 'white'} paddingBottom='10px'>
        {imageURL && (
          <Stage
            width={stageSize.width}
            height={stageSize.height}
            ref={stageRef}
            style={{ display: 'flex', justifyContent: 'center' }}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
          >
            <Layer>
              {imageURL && (
                <URLImage
                  src={imageURL}
                  scale={scale}
                  setStageSize={handleSetStageSize}
                  texts={texts}
                  setTexts={setTexts}
                  images={images}
                  setImages={setImages}
                  selectedId={selectedId}
                  setSelectedId={setSelectedId}
                  loadedImages={loadedImages}
                  setLoadedImages={setLoadedImages}
                />
              )}
            </Layer>
          </Stage>
        )}
      </Grid>
    </Grid>
  );
};

export default Poster;
