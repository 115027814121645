/** @jsxImportSource @emotion/react */
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  Stack,
  Tab,
  useMediaQuery,
} from '@mui/material';
import { Link, useNavigate, useRouterState } from '@tanstack/react-router';
import { SyntheticEvent, useContext, useEffect, useRef } from 'react';

import Breadcrumbs from '@/components/core/common/breadcrumbs/breadcrumbs';
import NavTabs from '@/components/core/common/nav-tabs/nav-tabs';
import TabPanel from '@/components/core/common/nav-tabs/tab-panel';
import translate from '@/components/core/common/translate';
import LoadingLayout from '@/components/layout/loading-layout';
import { useCurrentMenu } from '@/hooks/useCurrentMenu';
import { RoutePath, SearchParams } from '@/models/types';
import { _Icon as Icon, _translate as t, WeverCoreContext as Context } from '@/services/core';
import useCommunityStore from '@/store/community/community.store';
import { findByInNestedArray } from '@/utils/utils';
import StatusSelect from '@/views/action-sheets/components/status-select/status-select';

import { accordionSummaryStyle, accordionDetailsStyle, dashboardStyle } from './dashboard.style';

interface DashboardProps<T> {
  data?: T[] | undefined;
  subtitle: string;
  title: string;
}

const Dashboard = <T extends Record<string, any>>({ data, subtitle }: DashboardProps<T>) => {
  const { currentMenu, currentContent, search } = useCurrentMenu();
  const content = currentContent;
  const { locale }: { locale: string } = useContext(Context);
  const dashboards = (alias: string) => data?.filter((item) => item.tags?.includes(alias));

  const navigate = useNavigate();
  const { communityData, communityLoading } = useCommunityStore();
  const searchParamsContentRef = useRef(search.content);
  const searchParamsMenuRef = useRef(search.menu);
  const { location } = useRouterState();
  const isActionPage = location.pathname === '/action-sheets';
  const matches = useMediaQuery('(min-width:1200px)');

  useEffect(() => {
    if (!searchParamsContentRef.current || !searchParamsMenuRef.current) {
      const menu = findByInNestedArray(
        communityData?.menu ?? [],
        'url',
        window.location.pathname,
        'menu',
      );
      navigate({
        search: (oldSearchParams: SearchParams): SearchParams => ({
          ...oldSearchParams,
          menu: menu?.id,
          content: menu?.newContent?.[0].id,
          community: communityData?.id,
        }),
      });
    }
  }, [communityData?.menu, navigate, communityData?.id]);

  const handleChange = (_event: SyntheticEvent<Element, Event>, value: number) => {
    navigate({
      search: (oldSearchParams: SearchParams): SearchParams => ({
        ...oldSearchParams,
        content: value,
      }),
    });
  };

  if (communityLoading) {
    return <LoadingLayout />;
  }

  const renderItems = (workingFieldCategory: string) =>
    dashboards(workingFieldCategory)?.map((item, index: number) => (
      <div key={index} css={accordionDetailsStyle(communityData?.dataPrivate)}>
        <AccordionDetails className='accordion-details'>
          <Grid container alignItems='center' spacing={2}>
            <Grid item xs={12} lg={6}>
              <Stack paddingLeft={matches ? '55px' : '0'} textAlign={matches ? 'left' : 'center'}>
                <span className='title'>
                  {typeof item.name === 'object' ? translate(item.name) : t(item.name)}
                </span>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={isActionPage ? 3 : 6}>
              <Link
                className='icon-content'
                style={{ justifyContent: matches ? 'end' : 'center' }}
                to={`${location.pathname}/$id` as RoutePath}
                params={{
                  id: item.id,
                }}
                search={true}
              >
                <VisibilityIcon className='eye-icon' fontSize='small' />
                <span className='subtitle'>{subtitle}</span>
              </Link>
            </Grid>
            {isActionPage && (
              <Grid item xs={12} lg={3}>
                <Stack alignItems={matches ? 'flex-end' : 'center'}>
                  {isActionPage ? (
                    <StatusSelect
                      key={item.id}
                      actionSheetId={item.id}
                      actionSheetState={item.state}
                      stateMode={workingFieldCategory}
                    />
                  ) : null}
                </Stack>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </div>
    ));

  const renderCollapse = () =>
    content?.tags?.map((category, index: number) => (
      <div key={index} css={accordionSummaryStyle}>
        <Accordion className='accordion-content' disableGutters>
          <AccordionSummary
            className='accordion-summary'
            expandIcon={<Icon name={faAngleDown} className='angle-icon' />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <div className='header-content'>
              <FiberManualRecordIcon fontSize='small' className='circle-icon' />
              <span>{translate(category.name?.[locale])}</span>
            </div>
          </AccordionSummary>
          {renderItems(category.alias)}
        </Accordion>
      </div>
    ));

  const renderNavItems = currentMenu?.newContent?.map((content, index) => (
    <Tab key={index} label={translate(content.name?.[locale])} />
  ));

  if (communityLoading) {
    return <LoadingLayout />;
  }

  return (
    <div css={dashboardStyle}>
      <Container className='dashboard-container' maxWidth='xl'>
        <Breadcrumbs items={currentContent?.breadcrumbs} />
        <div className='content'>
          <NavTabs value={content?.id} onChange={handleChange}>
            {renderNavItems}
          </NavTabs>
          {!data ? (
            <LoadingLayout />
          ) : (
            <TabPanel value={content?.id} index={content?.id}>
              {renderCollapse()}
            </TabPanel>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Dashboard;
