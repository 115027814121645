import {
  Breadcrumb,
  Community,
  ContentItem,
  MenuConfiguration,
  MenuItem,
  NameTrait,
  Tag,
} from '@/models/types';
import { _Environment } from '@/services/core';

export const defaultLocale = _Environment.get('locale');

export const getNumbersFromString = (string: string) => {
  const numsStr = string.replace(/[^0-9]/g, '');
  return parseInt(numsStr);
};

export const getTagByAlias = (alias: string, tags: Tag[] | undefined) =>
  tags && tags?.find((tag) => tag.alias === alias)?.names;

export const containsNumbers = (str: string) => {
  return /[0-9]/.test(str);
};

export const translate = (name: string | NameTrait | undefined, locale: string): string => {
  if (typeof name === 'object' && name !== null) {
    return name[locale] || name['fr'] || name['en'];
  }
  return name || '';
};

export const transformString = (str: string) => {
  if (str === str.toLowerCase()) {
    str = str.charAt(0).toUpperCase() + str.slice(1);
  }
  if (str.includes('-')) {
    return str
      .split('-')
      .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
      .join('');
  }
  return str;
};

interface NestedItem<T> {
  [key: string]: any;
  children?: T[];
}

export const findByInNestedArray = <T extends NestedItem<T>>(
  array: T[],
  key: string,
  value: any,
  childName: string,
): T | null => {
  let result: T | null = null;

  const searchInArray = (subArray: T[]): boolean => {
    return subArray.some((item) => {
      if (item[key] === value) {
        result = item;
        return true; // Stops the iteration once the item is found
      }

      if (item[childName] && searchInArray(item[childName])) {
        return true; // Stops the iteration if the item is found in children
      }

      return false; // Continues the iteration
    });
  };

  searchInArray(array);

  return result;
};

export const isMapView = !!window.location.pathname.includes('maps');

export const convertDateToDDMMYYYY = (isoDateString: string): string => {
  const date = new Date(isoDateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const mapRecursive = <T>(
  key: keyof T,
  oldArray: Array<T & { key?: T[] }>,
  callback: (item: T, index: number) => T,
  newArray: T[] = [],
): T[] => {
  if (oldArray.length <= 0) {
    return newArray;
  } else {
    const [item, ...theRest] = oldArray; // Utilisation de 'const' au lieu de 'let'
    let newItem = item;
    if (newItem[key] && Array.isArray(newItem[key])) {
      newItem = {
        ...newItem,
        [key]: mapRecursive<T>(key, newItem[key] as Array<T & { key?: T[] }>, callback),
      };
    }
    const interimArray = [...newArray, callback(newItem, newArray.length)];
    return mapRecursive<T>(key, theRest, callback, interimArray);
  }
};

export const ensureHashInColors = (colors: Community['dataPrivate']) => {
  (Object.keys(colors) as Array<keyof Community['dataPrivate']>).forEach((key) => {
    const color = colors[key];
    if (!color!.value.startsWith('#')) {
      color!.value = '#' + color!.value;
    }
  });
  return colors;
};

export const defaultDataPrivate = {
  primaryColor: {
    name: 'primaryColor',
    value: '#00aad7',
  },
  secondaryColor: {
    name: 'secondaryColor',
    value: '#d73755',
  },
};

export const extractTagsAndContentKeys = (menuItems: MenuConfiguration[]): string[] => {
  const tagsAndKeysSet = new Set<string>();

  const extract = (items: MenuConfiguration[]) => {
    items.forEach((item) => {
      if (item.tag) {
        tagsAndKeysSet.add(item.tag);
      }
      if (item.content) {
        Object.keys(item.content).forEach((key) => {
          tagsAndKeysSet.add(key);
          item.content![key].forEach((contentValue) => tagsAndKeysSet.add(contentValue));
        });
      }
      if (item.menu) {
        extract(item.menu);
      }
    });
  };

  extract(menuItems);
  return Array.from(tagsAndKeysSet);
};

const findParentNames = (chain: MenuConfiguration[], key: keyof MenuConfiguration): string[] =>
  chain.map((parent) => parent[key] as string);

const transformContent = (
  content: Record<string, string[]>,
  tagsData: Tag[],
  parentChain: MenuConfiguration[],
): ContentItem[] => {
  return Object.entries(content).map(([key, value], index): ContentItem => {
    const uniqueFilterTags = new Set([...findParentNames(parentChain, 'tag'), key]);

    return {
      id: index,
      name: getTagByAlias(key, tagsData),
      alias: key,
      filterTags: Array.from(uniqueFilterTags).filter(Boolean),
      tags: value.map((tag) => ({
        name: getTagByAlias(tag, tagsData),
        alias: tag,
      })),
    };
  });
};

export const createBreadcrumbs = (menu: MenuConfiguration, homepage?: boolean) => {
  if (homepage && homepage === true && menu.id === 0) {
    return { url: '/homepage', params: null };
  }
  return { url: `/${menu.url}`, params: menu.content };
};

const addBreadcrumbsToContent = (
  contentItems: ContentItem[],
  breadcrumbs: Breadcrumb[],
  url: string | null,
) => {
  return contentItems.map((contentItem) => ({
    ...contentItem,
    breadcrumbs: [...breadcrumbs, { name: contentItem.name, url }], // Add current content item name to breadcrumbs
  }));
};

const addBreadcrumbsToMenu = (
  menu: MenuItem[],
  parentBreadcrumbs: Breadcrumb[] = [],
  homepage?: boolean,
) => {
  return menu.map((item): MenuItem => {
    const currentBreadcrumb: Breadcrumb = {
      name: item.name,
      url:
        item.id === 0 && homepage && homepage === true
          ? '/homepage'
          : item.url
          ? `/${item.url}`
          : null,
    };

    const contentBreadcrumb: Breadcrumb = {
      name: item.newContent?.[0].alias,
      url: null,
    };

    const breadcrumbs = !item.newContent
      ? [...parentBreadcrumbs, currentBreadcrumb]
      : [...parentBreadcrumbs, currentBreadcrumb, contentBreadcrumb];

    const newContent = item.newContent
      ? addBreadcrumbsToContent(
          item.newContent,
          [...parentBreadcrumbs, currentBreadcrumb],
          item.url ? `/${item.url}` : null,
        )
      : undefined;

    const submenu = item.menu ? addBreadcrumbsToMenu(item.menu, breadcrumbs) : undefined;

    return {
      ...item,
      breadcrumbs,
      menu: submenu,
      newContent,
    };
  });
};

let idCounter = 0;
export const transformMenu = (
  menu: MenuConfiguration[],
  tagsData: Tag[],
  parentChain: MenuConfiguration[] = [],
  isInitialCall: boolean = true,
): MenuItem[] => {
  if (isInitialCall) {
    idCounter = 0;
  }
  const generateId = () => idCounter++;
  const newMenu = menu.map((item): MenuItem => {
    const newItem: MenuItem = {
      id: generateId(),
      title: item.title,
      isDynamicHomePage: item.isDynamicHomePage,
      homepage: item.homepage,
      name: item.tag ? getTagByAlias(item.tag, tagsData) : item.title,
      url: item.homepage ? '/homepage' : item.url ? `/${item.url}` : null,
      tag: item.tag,
      icon: item.icon,
      parentName: findParentNames(parentChain, 'title'),
      newContent: item.content
        ? transformContent(item.content, tagsData, parentChain.concat(item))
        : undefined,
    };

    if (item.menu) {
      newItem.menu = transformMenu(item.menu, tagsData, parentChain.concat(item), false);
    }

    return newItem;
  });

  return addBreadcrumbsToMenu(newMenu, [], menu[0].homepage);
};

export const findAllLastChildrenIds = (menu: MenuItem[]) => {
  const lastLevelMenuIds: number[] = [];

  const traverseMenu = (items: MenuItem[], skipFirst: boolean = false) => {
    items.forEach((item) => {
      if (skipFirst && !(item.menu && item.menu.length > 0)) {
        return;
      }
      if (item.menu && item.menu.length > 0) {
        // If the item has a nested menu, recursively traverse it
        traverseMenu(item.menu);
      } else {
        // If the item does not have a nested menu, it's a last level item
        lastLevelMenuIds.push(item.id);
      }
    });
  };

  traverseMenu(menu, true);

  return lastLevelMenuIds;
};

export const findAllChildrenIds = (menu: MenuItem[]) => {
  const lastLevelMenuIds: number[] = [];

  const traverseMenu = (items: MenuItem[], skipFirst: boolean = false) => {
    items.forEach((item) => {
      if (skipFirst && !(item.menu && item.menu.length > 0)) {
        return;
      }
      if (item.menu && item.menu.length > 0) {
        // If the item has a nested menu, recursively traverse it
        lastLevelMenuIds.push(item.id);
        traverseMenu(item.menu);
      }
    });
  };

  traverseMenu(menu);

  return lastLevelMenuIds;
};

export const findObjectWithNewContent = (array: MenuItem[]) => {
  const baseContent: MenuItem[] = [];
  array &&
    mapRecursive('menu', array, (menu: MenuItem) => {
      if (menu.newContent) {
        baseContent.push(menu);
      }
      return menu;
    });
  return baseContent[0];
};

export const getMenuTags = (array: MenuItem[], locale: string) => {
  const baseContent: {alias: string, name: string}[] = [];
  array &&
    mapRecursive('menu', array, (menu: MenuItem) => {
      if (menu.newContent) {
        const tags = menu.newContent.flatMap((content) => content.tags.map((tag) => ({alias: tag.alias, name: tag.name?.[locale] ?? '' })));
        baseContent.push(...tags);
      }
      return menu;
    });
  return baseContent;
};
  
