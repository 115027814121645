import { Container } from '@mui/material';
import { Navigate } from '@tanstack/react-router';
import { FC, ReactNode } from 'react';

import LoadingLayout from '@/components/layout/loading-layout';
import { SearchParams } from '@/models/types';
import useCommunityStore from '@/store/community/community.store';

interface RedirectRouteProps {
  children: ReactNode;
}

const RedirectRoute: FC<RedirectRouteProps> = ({ children }) => {
  const { communityData } = useCommunityStore();
  const firstMenu = communityData?.firstMenu;

  return window.location.pathname === '/' ? (
    !firstMenu ? (
      <Container maxWidth='xl'>
        <LoadingLayout />
      </Container>
    ) : communityData?.menu[0].homepage ? (
      <Navigate
        to='/homepage'
        search={(oldParams: SearchParams) => ({
          ...oldParams,
          menu: firstMenu.id,
          content: firstMenu.newContent?.[0].id,
        })}
        replace={true}
      />
    ) : (
      <Navigate
        to={firstMenu?.url ?? ''}
        search={(oldParams: SearchParams) => ({
          ...oldParams,
          menu: firstMenu.id,
          content: firstMenu.newContent?.[0].id,
        })}
        replace={true}
      />
    )
  ) : (
    children
  );
};

export default RedirectRoute;
