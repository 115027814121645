/** @jsxImportSource @emotion/react */

import { Container, Tab } from '@mui/material';
import { useState } from 'react';

import Breadcrumbs from '@/components/core/common/breadcrumbs/breadcrumbs';
import NavTabs from '@/components/core/common/nav-tabs/nav-tabs';
import TabPanel from '@/components/core/common/nav-tabs/tab-panel';
import LoadingLayout from '@/components/layout/loading-layout';
import { useGetStructureList } from '@/hooks/queries/useGetStructureList';
import { useCurrentMenu } from '@/hooks/useCurrentMenu';
import { StructureValidationState, StructureValidationStateEnum } from '@/models/types';
import { _Api, _translate as t } from '@/services/core';
import useCommunityStore from '@/store/community/community.store';
import StructureTable from '@/views/structure-list/components/structures-table';
import useStructureList from '@/views/structure-list/use-structures-list';

import { structureListTabs } from './helpers';
import { structuresStyle } from './structure.style';

const StructureList = () => {
  const { handleChange, activeTab } = useStructureList();
  const { currentMenu } = useCurrentMenu();
  const [updateLoading, setUpdateLoading] = useState(false);

  const { data, isLoading, refetch } = useGetStructureList();
  const { communityLoading, communityData } = useCommunityStore();

  const updateStatus = async (structureId: number) => {
    setUpdateLoading(true);
    await _Api.get(
      `/structure_referencements/create/${structureId}/${communityData?.id}/${StructureValidationStateEnum.accepted}`,
    );
    await refetch();
    setUpdateLoading(false);
  };

  const currentTabPanel = structureListTabs[activeTab];

  const showTable = data?.some((structure) => structure.state === currentTabPanel.status);

  const renderNavItems = structureListTabs.map(
    (tab, index) => tab.status && <Tab key={index} label={t(tab.title)} />,
  );

  if (communityLoading) {
    return <LoadingLayout />;
  }

  return (
    <div css={structuresStyle}>
      <Container className='structure-list-container' maxWidth='xl'>
        <Breadcrumbs
          items={[...(currentMenu?.breadcrumbs || []), { name: currentTabPanel?.title, url: '' }]}
        />
        <div className='structure-content'>
          <NavTabs value={activeTab} onChange={handleChange}>
            {renderNavItems}
          </NavTabs>
          {isLoading || updateLoading ? (
            <LoadingLayout />
          ) : (
            <div className='structure-list-content'>
              <TabPanel value={activeTab} index={activeTab}>
                {showTable ? (
                  <StructureTable
                    data={data}
                    status={currentTabPanel.status as StructureValidationState}
                    updateStatus={updateStatus}
                  />
                ) : (
                  <div className='structure-error'>{t(currentTabPanel?.error)}</div>
                )}
              </TabPanel>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default StructureList;
