import Konva from 'konva';
import React, { useState, useRef, useEffect } from 'react';
import { Image as KonvaImage, Text, Transformer } from 'react-konva';
import useImage from 'use-image';

export interface TextItem {
  id: string;
  text: string;
  color: string;
  fontSize: number;
  fontWeight: string;
  x: number;
  y: number;
  isCommunityData: boolean;
}

export interface ImageItem {
  id: string;
  src: string;
  x: number;
  y: number;
  width: number;
  height: number;
  isCommunityData: boolean;
}

interface URLImageProps {
  src: string;
  scale: number;
  setStageSize: (width: number, height: number) => void;
  texts: TextItem[];
  setTexts: (texts: TextItem[]) => void;
  images: ImageItem[];
  setImages: (images: ImageItem[]) => void;
  selectedId: string | null;
  setSelectedId: (id: string | null) => void;
  loadedImages: { [key: string]: HTMLImageElement | null };
  setLoadedImages: React.Dispatch<
    React.SetStateAction<{
      [key: string]: HTMLImageElement | null;
    }>
  >;
}

const URLImage: React.FC<URLImageProps> = ({
  src,
  scale,
  setStageSize,
  texts,
  setTexts,
  images,
  setImages,
  selectedId,
  setSelectedId,
  loadedImages,
  setLoadedImages,
}) => {
  const [backgroundImage] = useImage(src);
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });

  const logoRef = useRef(null);
  const textRef = useRef(null);
  const trRef = useRef<Konva.Transformer>(null);
  const imageRefs = useRef<{ [key: string]: any }>({});

  useEffect(() => {
    if (backgroundImage) {
      const newDimensions = {
        width: backgroundImage.width * scale,
        height: backgroundImage.height * scale,
      };
      if (newDimensions.width !== dimensions.width || newDimensions.height !== dimensions.height) {
        setDimensions(newDimensions);
        setStageSize(newDimensions.width, newDimensions.height);
      }
    }
  }, [backgroundImage, scale, setStageSize, dimensions]);

  useEffect(() => {
    if (selectedId) {
      // we need to attach transformer manually
      trRef.current?.nodes(
        [logoRef.current, textRef.current, ...Object.values(imageRefs.current)].filter(
          (node) => node && node.attrs.id === selectedId,
        ),
      );
      trRef.current?.getLayer()?.batchDraw();
    }
  }, [selectedId, logoRef, textRef, imageRefs]);

  useEffect(() => {
    images.forEach((imageItem) => {
      if (!loadedImages[imageItem.id]) {
        const img = new window.Image();
        img.src = imageItem.src;
        img.crossOrigin = 'Anonymous';
        img.referrerPolicy = 'origin';
        img.onload = () => {
          setLoadedImages((prev) => ({ ...prev, [imageItem.id]: img }));
        };
      }
    });
  }, [images, loadedImages, setLoadedImages]);

  const handleSelect = (e: Konva.KonvaEventObject<MouseEvent>) => {
    const id = e.target.id();
    setSelectedId(id);
  };

  const handleTextDragEnd = (e: Konva.KonvaEventObject<DragEvent>, id: string) => {
    const newTexts = texts.map((text) =>
      text.id === id ? { ...text, x: e.target.x(), y: e.target.y() } : text,
    );
    setTexts(newTexts);
  };

  const handleTextTransformEnd = (e: Konva.KonvaEventObject<Event>, id: string) => {
    const node = e.target;
    const newTexts = texts.map((text) =>
      text.id === id
        ? {
            ...text,
            x: node.x(),
            y: node.y(),
            fontSize: node.scaleX() * text.fontSize,
          }
        : text,
    );
    setTexts(newTexts);
  };

  const handleImageDragEnd = (e: Konva.KonvaEventObject<DragEvent>, id: string) => {
    const newImages = images.map((image) =>
      image.id === id ? { ...image, x: e.target.x(), y: e.target.y() } : image,
    );
    setImages(newImages);
  };

  const handleImageTransformEnd = (e: Konva.KonvaEventObject<Event>, id: string) => {
    const node = e.target;
    const newImages = images.map((image) =>
      image.id === id
        ? {
            ...image,
            x: node.x(),
            y: node.y(),
            width: node.width() * node.scaleX(),
            height: node.height() * node.scaleY(),
          }
        : image,
    );
    setImages(newImages);

    // Reset the scale to 1 after resizing
    node.scaleX(1);
    node.scaleY(1);
  };

  return (
    <>
      <KonvaImage
        image={backgroundImage}
        x={0}
        y={0}
        width={dimensions.width}
        height={dimensions.height}
      />
      {texts.map((textItem) => (
        <Text
          key={textItem.id}
          text={textItem.text}
          x={textItem.x}
          y={textItem.y}
          fontSize={textItem.fontSize * scale}
          fontStyle={textItem.fontWeight}
          fill={textItem.color}
          draggable
          onClick={handleSelect}
          onTap={handleSelect}
          id={textItem.id}
          onDragEnd={(e) => handleTextDragEnd(e, textItem.id)}
          onTransformEnd={(e) => handleTextTransformEnd(e, textItem.id)}
        />
      ))}
      {images.map((imageItem) => (
        <KonvaImage
          key={imageItem.id}
          image={loadedImages[imageItem.id] || undefined} // Ensure image is not null
          x={imageItem.x}
          y={imageItem.y}
          width={imageItem.width}
          height={imageItem.height}
          draggable
          onClick={handleSelect}
          onTap={handleSelect}
          id={imageItem.id}
          ref={(node) => (imageRefs.current[imageItem.id] = node)}
          onDragEnd={(e) => handleImageDragEnd(e, imageItem.id)}
          onTransformEnd={(e) => handleImageTransformEnd(e, imageItem.id)}
        />
      ))}

      {selectedId && (
        <Transformer
          ref={trRef}
          flipEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default URLImage;
