import { Navigate } from '@tanstack/react-router';
import { FC, ReactNode } from 'react';

import useAuthStore from '@/store/auth/auth.store';

interface PrivateRouteProps {
  children: ReactNode;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ children }) => {
  const { isAuthenticated } = useAuthStore();
  return isAuthenticated() ? children : <Navigate to='/login' />;
};

export default PrivateRoute;
