import { Container } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import React from 'react';

import TabPanel from '@/components/core/common/nav-tabs/tab-panel';
import translate from '@/components/core/common/translate';
import { ActionsSheetInterface } from '@/models/types';
import { _translate as t } from '@/services/core';

const DescriptionTab = ({
  actionSheet,
  tabId,
}: {
  actionSheet: ActionsSheetInterface;
  tabId?: number;
}) => {
  const [frontImg, setFrontImg] = useState<string | null>(null);

  const manageFrontImg = useCallback(() => {
    const index = actionSheet.medias?.findIndex((media) => {
      return media.mimeType && media.mimeType.includes('image');
    });
    if (index === -1 || typeof actionSheet.medias === 'undefined') {
      setFrontImg(null);
    } else {
      setFrontImg(actionSheet.medias && actionSheet.medias[index as number]?.file?.path);
    }
  }, [actionSheet.medias]);

  useEffect(() => {
    manageFrontImg();
  }, [manageFrontImg]);

  const normalizeActionSheetCategory =
    actionSheet.categories?.length > 0 ? (
      actionSheet.categories?.map((category, index) => (
        <div className='category' key={index}>
          <span>{category.name}</span>
        </div>
      ))
    ) : (
      <span>N/A</span>
    );
  return (
    <TabPanel value={tabId} index={0}>
      <Container maxWidth='xl'>
        <div className='description-tab'>
          <div className='header'>
            <div className='categories'>
              <span>{t('adminapp_categories')}</span>
              {normalizeActionSheetCategory}
            </div>
          </div>
          <div className='content'>
            <div className='presentation-box'>
              <img
                style={{ height: 'auto', width: 'auto', maxHeight: '300', maxWidth: '500px' }}
                src={frontImg as string}
                alt={`img_${Math.random() * 1000}_${frontImg}`}
                hidden={!frontImg}
              />
              <div>
                <div className={`presentation ${!frontImg && 'no-image'}`}>
                  <h3>{t('adminapp_presentation')}</h3>
                  {actionSheet.presentation ? (
                    <span>{actionSheet.presentation}</span>
                  ) : (
                    <span>{t('adminapp_no_value_presentation')}</span>
                  )}
                  <h3 className='objectives'>{t('adminapp_objectives')}</h3>
                  {Array.isArray(actionSheet.objectives) && actionSheet.objectives?.length === 0 ? (
                    <span>{t('adminapp_no_value_objectives')}</span>
                  ) : (
                    <ul>
                      {actionSheet.objectives?.map((objective, index) => (
                        <li key={index}>{translate(objective.name)}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <h3>{t('adminapp_detailled_description')}</h3>
            {actionSheet.description ? (
              <span>{actionSheet.description}</span>
            ) : (
              <span>{t('adminapp_no_value_detailed_description')}</span>
            )}
            <h3>{t('adminapp_sheet_action_evaluation_criteria')}</h3>
            {Array.isArray(actionSheet.evaluationCriteria) &&
            actionSheet.evaluationCriteria?.length === 0 ? (
              <span>{t('adminapp_no_value_eval_criteria')}</span>
            ) : (
              <ul>
                {actionSheet.evaluationCriteria?.map((criteria, index) => (
                  <li key={index}>{criteria?.value}</li>
                ))}
              </ul>
            )}
            <div className='medias'>
              <h3>{t('adminapp_useful_medias')}</h3>
              {actionSheet.medias?.length === 0 ? (
                <span>{t('adminapp_no_value_useful_media')}</span>
              ) : (
                <span>{t('adminapp_no_value_useful_media')}</span>
              )}
            </div>
          </div>
        </div>
      </Container>
    </TabPanel>
  );
};

export default DescriptionTab;
