import DomService from 'wever-core/dist/services/utils/dom.js'
import ObjectService from 'wever-core/dist/services/utils/object.js'
import StringService from 'wever-core/dist/services/utils/string.js'

export const _DomService = DomService
export const _ObjectService = ObjectService
export const _StringService = StringService

export default {
  _DomService,
  _ObjectService,
  _StringService,
}
