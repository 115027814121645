import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { StructureReferencement } from '@/models/types';
import { getStructureList } from '@/services/api';
import useCommunityStore from '@/store/community/community.store';

export const useGetStructureList = () => {
  const { communityData } = useCommunityStore();
  return useQuery<StructureReferencement[], AxiosError>({
    queryKey: ['structure', communityData!.id],
    queryFn: () => getStructureList(communityData!.id!),
    enabled: !!communityData!.id,
  });
};
