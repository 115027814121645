import { useParams } from '@tanstack/react-router';
import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { getUniqueListBy } from '@/components/core/common/helpers';
import { NameTrait } from '@/models/entities/common-types';
import { Community, ContentItem, DashboardLayoutType, GraphInterface } from '@/models/types';
import { _Api } from '@/services/core';

const useSingleGraph = (
  data: DashboardLayoutType[] | undefined,
  content: ContentItem,
  locale: string,
  communityData?: Community,
) => {
  const [singleGraph, setSingleGraph] = useState<GraphInterface | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reportId, setReportId] = useState<number | null>(null);

  const { id }: { id: string } = useParams({ strict: false });

  const getSingleGraph = useCallback(async () => {
    setIsLoading(true);
    const response: AxiosResponse<GraphInterface> = await _Api.get(
      `/dashboard_layouts/${id}/public`,
    );
    if (response) {
      setSingleGraph(response.data);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    const report = communityData?.report.replace('/reports/', '');
    setReportId(report ? parseInt(report) : null);
    getSingleGraph();
  }, [getSingleGraph, communityData]);

  const workingFieldCategories = getUniqueListBy<{ name: NameTrait | undefined; alias: string }>(
    content?.tags,
    'alias',
  ).map((category) => category.alias);

  const itemsByTags = data?.filter((item) =>
    item.tags?.some((tag) => workingFieldCategories.includes(tag)),
  );

  const groupedDataByJourneyType = itemsByTags
    ?.map((item) => ({
      ...item,
      journeyType:
        content?.tags?.find((tag) => item.tags?.includes(tag.alias))?.name?.[locale] ?? '',
    }))
    .sort((a, b) => {
      return a.journeyType.localeCompare(b.journeyType);
    });

  return { singleGraph, isLoading, reportId, groupedDataByJourneyType };
};

export default useSingleGraph;
