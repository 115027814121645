import { ActionSheetState } from '@/models/entities/common-types';
import { LoginFormInputSchema } from '@/models/schemas';
import {
  ActionSheetsType,
  Media,
  MenuConfiguration,
  StructureValidationState,
} from '@/models/types';
import { _Api, _Security } from '@/services/core';
import {
  getNumbersFromString,
  extractTagsAndContentKeys,
  transformMenu,
  findObjectWithNewContent,
  ensureHashInColors,
  defaultDataPrivate,
} from '@/utils/utils';

export const login = async (formInput: LoginFormInputSchema) => {
  const { username, password } = formInput;
  const response = await _Api.post('/login_check', { username, password });
  return response.data;
};

export const getLanguages = async () => {
  const response = await _Api.get('/languages', { perPage: 1000 });
  return response.data['hydra:member'];
};

export const getTags = async (tagsAndContentKeys: string[]) => {
  const response = await _Api.get(`/managerapp/tagContent`, {
    tagContent: JSON.stringify(tagsAndContentKeys),
  });
  return response.data;
};

export const getUserCommunities = async () => {
  const response = await _Api.get(`/communities/me?all=true`);
  return response.data['hydra:member'];
};

export const getCommunityManagerConfiguration = async (
  communityId: number,
): Promise<{ menu: MenuConfiguration[] }> => {
  const response = await _Api.get(`/managerapp/menu/${communityId}`);
  return response.data;
};

export const getCommunityLogo = async (logo: string) => {
  const response = await _Api.get(`/medias/${getNumbersFromString(logo)}`);
  return response.data;
};

export const getCommunity = async (communityId: number) => {
  const communityResponse = await _Api.get(`/communities/${communityId}`);
  const logoData = communityResponse.data.logo
    ? await getCommunityLogo(communityResponse.data.logo)
    : null;
  const configuration = await getCommunityManagerConfiguration(communityId);
  const tagsAndContentKeys = extractTagsAndContentKeys(configuration.menu);
  const tagsData = await getTags(tagsAndContentKeys);

  if (_Security.isGranted('ROLE_ADMIN') || _Security.user.username === 'manager1@wever.fr') {
    configuration.menu.push({
      title: 'manager_app_analytics',
      url: 'analytics',
    }, 
    {
      title: 'manager_app_poster',
      url: 'poster',
    }
  );
  }

  const transformedMenu = transformMenu(configuration.menu, tagsData);

  const newData = {
    ...communityResponse.data,
    logo: logoData,
    menu: transformedMenu,
    firstMenu: findObjectWithNewContent(transformedMenu),
    tags: tagsData,
    dataPrivate:
      Object.keys(communityResponse.data.dataPrivate).length > 0
        ? ensureHashInColors(communityResponse.data.dataPrivate)
        : defaultDataPrivate,
  };

  return {
    ...newData,
  };
};

export const getDashboardsLayout = async (tags: string[]) => {
  const response = await _Api.get(`/managerapp/tags/dashboards`, {
    tags: JSON.stringify(tags),
  });
  return response.data;
};

export const getActionSheets = async (tags: string[], communityId: number | undefined) => {
  const response = await _Api.get(`/managerapp/tags/sheetactions`, {
    tags: JSON.stringify(tags),
  });
  const sheetActionsCommunity = await _Api.get(`/communities/manager/sheet_actions`, { id: communityId });

  const combinedData = response.data.map((item: ActionSheetsType) => {
    const matchingSheetAction = sheetActionsCommunity.data[0].sheetActions.find(
      ({ sheetAction }: { sheetAction: string }) => sheetAction.replace('/sheet_actions/', '') === item.id.toString()
    );
    if (matchingSheetAction) {
      return { ...item, state: matchingSheetAction.state ?? null };
    }
    return null;
  });

  const filteredData = combinedData.filter((item: ActionSheetsType | null): item is ActionSheetsType => item !== null);

  const sortedData = filteredData.sort((a: ActionSheetsType, b: ActionSheetsType) => {
    const stateOrder = { accepted: 1, waiting: 2, denied: 3, null: 4 };
    return stateOrder[a.state as keyof typeof stateOrder] - stateOrder[b.state as keyof typeof stateOrder];
  });
  return sortedData;
}

export const uploadRessource = async (
  formData: FormData,
  filename: string,
  communityId: number,
) => {
  const response = await _Api.post(
      `media/private/community/${communityId}`,
      formData,
      { filename },
      null,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  return response;
};

export const downloadRessource = async (media: Media, communityId: number) => {
  const response = await _Api.get(`/media/private/community/${communityId}/${media.id}`, null, {
    responseType: 'arraybuffer',
  });
  const url = window.URL.createObjectURL(
    new Blob([response.data], { type: media.mimeType ?? 'application/zip' }),
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${media.originalName}`);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
  return response.data;
};

export const deleteRessource = async (media: Media, communityId: number) => {
  const response = await _Api.delete(`/media/private/community/${communityId}/${media.id}`, null, {
    responseType: 'arraybuffer',
  });

  return response.message;
};

export const getStructureList = async (communityId: number) => {
  const response = await _Api.get(`/structure_referencements/community/${communityId}`);
  return response.data;
};

export const updateStructureStatus = async (
  structureId: number,
  communityId: number,
  status: StructureValidationState,
) => {
  const response = await _Api.get(
    `/structure_referencements/create/${structureId}/${communityId}/${status}`,
  );
  return response.data;
};

export const getFormInformations = async (communityId: number, locale: string) => {
  const response = await _Api.get(`/managerapp/config/${communityId}?key=information.diagnosticId`);
  const diagnosticId = response?.data.config;

  const formInformations = await _Api.get(
    `/diagnostics/embeded/${diagnosticId}/${communityId}?locale=${locale}&resource=community`,
  );

  return { config: formInformations.data, diagnosticId };
};

export const updateActionSheetState = async (actionSheetId: number, communityId: number, state: ActionSheetState, stateMode: string) => {
  const response = await _Api.post(`/sheet_actions/update_status/${actionSheetId}/${communityId}/${state}/${stateMode}`);
  return response.data;
};

