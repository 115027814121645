import { useParams } from '@tanstack/react-router';
import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { getUniqueListBy } from '@/components/core/common/helpers';
import { ActionSheetState, NameTrait } from '@/models/entities/common-types';
import { _Api } from '@/services/core';
import { ActionSheetsType, ActionsSheetInterface, ContentItem } from '@/models/types';
import useCommunityStore from '@/store/community/community.store';

const useSingleActionSheet = (
  data: ActionSheetsType[] | undefined,
  content: ContentItem,
  locale: string,
) => {
  const { communityData } = useCommunityStore();
  const [actionSheet, setActionSheet] = useState<ActionsSheetInterface & { state: ActionSheetState | null } | undefined>(undefined);
  const [activeTab, setActiveTab] = useState<number | undefined>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { id }: { id: string } = useParams({ strict: false });

  const getActionSheet = useCallback(async () => {
    setIsLoading(true);
    const response: AxiosResponse<ActionsSheetInterface> = await _Api.get(`/sheet_actions/${id}`);

    const sheetActionsCommunity = await _Api.get(`/communities/manager/sheet_actions`, { id: communityData?.id });

    const matchingSheetAction = sheetActionsCommunity.data[0].sheetActions.find(
      ({ sheetAction }: { sheetAction: string }) => sheetAction.replace('/sheet_actions/', '') === id
    );

    if (response) {
      setActionSheet({ ...response.data, state: matchingSheetAction?.state });
    }
    setIsLoading(false);
  }, [id, communityData?.id]);

  useEffect(() => {
    getActionSheet();
  }, [getActionSheet]);

  const workingFieldCategories = getUniqueListBy<{ name: NameTrait | undefined; alias: string }>(
    content?.tags,
    'alias',
  ).map((category) => category.alias);

  const itemsByTags = data?.filter((item) =>
    item.tags?.some((tag) => workingFieldCategories.includes(tag)),
  );

  const groupedDataByJourneyType = itemsByTags
    ?.map((item) => ({
      ...item,
      alias: content?.tags?.find((tag) => item.tags?.includes(tag.alias))?.alias ?? '',
      journeyType:
        content?.tags?.find((tag) => item.tags?.includes(tag.alias))?.name?.[locale] ?? '',
    }))
    .sort((a, b) => {
      return a.journeyType.localeCompare(b.journeyType);
    });

  return { actionSheet, activeTab, isLoading, setActiveTab, groupedDataByJourneyType };
};

export default useSingleActionSheet;
