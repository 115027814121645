/** @jsxImportSource @emotion/react */

import { Container, Tab } from '@mui/material';

import Breadcrumbs from '@/components/core/common/breadcrumbs/breadcrumbs';
import NavTabs from '@/components/core/common/nav-tabs/nav-tabs';
import TabPanel from '@/components/core/common/nav-tabs/tab-panel';
import LoadingLayout from '@/components/layout/loading-layout';
import { useCurrentMenu } from '@/hooks/useCurrentMenu';
import { _translate as t } from '@/services/core';
import useCommunityStore from '@/store/community/community.store';
import RessourcesTable from '@/views/ressources/components/ressources-table/ressources-table';
import Uplaod from '@/views/ressources/components/upload';
import { ressourcesTabs } from '@/views/ressources/helpers';
import useRessources from '@/views/ressources/use-ressources';

import { ressourcesStyle } from './ressources.style';

const Ressources = () => {
  const { activeTab, setActiveTab, uploadLoading, uploadRessource } = useRessources();
  const { communityData, communityLoading } = useCommunityStore();
  const { currentMenu } = useCurrentMenu();

  const breadCrumbsItems = [
    ...(currentMenu?.breadcrumbs || []),
    { name: ressourcesTabs[activeTab ?? 0].title, url: '' },
  ];

  const renderNavItems = ressourcesTabs.map((tab, index) => (
    <Tab key={index} label={t(tab.title)} />
  ));

  if (communityLoading) {
    return <LoadingLayout />;
  }

  const handleChange = (_event: React.SyntheticEvent<Element, Event>, value: number) => {
    setActiveTab(value);
  };

  return (
    <div css={ressourcesStyle}>
      <Container className='ressources-container' maxWidth='xl'>
        <Breadcrumbs items={breadCrumbsItems} />
        <div className='ressources-content'>
          <NavTabs value={activeTab} onChange={handleChange}>
            {renderNavItems}
          </NavTabs>
          <TabPanel value={activeTab} index={0}>
            {communityData &&
            communityData.mediaPrivates &&
            communityData.mediaPrivates.length > 0 ? (
              <div className='collapse-box'>
                <RessourcesTable />{' '}
              </div>
            ) : (
              <div className='structure-error'>{t('manager_app_no_found_ressources')}</div>
            )}
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            {!uploadLoading ? (
              <div className='collapse-box'>
                <Uplaod uploadRessource={uploadRessource} />
              </div>
            ) : (
              <LoadingLayout />
            )}
          </TabPanel>
        </div>
      </Container>
    </div>
  );
};

export default Ressources;
