import { useNavigate, useSearch } from '@tanstack/react-router';
import { useEffect } from 'react';

import { useGetCommunity } from '@/hooks/queries/useGetCommunity';
import { useGetLanguages } from '@/hooks/queries/useGetLanguages';
import { useGetUserCommunites } from '@/hooks/queries/useGetUserCommunites';
import { SearchParams } from '@/models/types';
import { _Storage } from '@/services/core';
import useCommunityStore from '@/store/community/community.store';

export const useLayout = () => {
  const navigate = useNavigate();

  const search = useSearch({
    strict: false,
  }) satisfies SearchParams;

  const { data: languages } = useGetLanguages();
  const { data: userCommunities, isLoading: userCommunitesLoading } = useGetUserCommunites();
  const communityId =
    search.community ?? _Storage.get('Community') ?? userCommunities?.[0].id ?? null;
  const { setCommunityData, setCommunityLoading } = useCommunityStore();

  const {
    data: currentCommunity,
    isLoading: communityLoading,
    isSuccess,
  } = useGetCommunity(communityId);

  useEffect(() => {
    if (isSuccess) {
      setCommunityData(currentCommunity);
      _Storage.set('Community', currentCommunity?.id);
      navigate({
        search: (oldSearchParams: SearchParams): SearchParams => ({
          ...oldSearchParams,
          community: currentCommunity.id,
        }),
      });
    }
  }, [isSuccess, currentCommunity, setCommunityData, communityId, navigate]);

  useEffect(() => {
    setCommunityLoading(communityLoading);
  }, [communityLoading, setCommunityLoading]);

  return {
    languages,
    userCommunities,
    userCommunitesLoading,
    currentCommunity,
    communityLoading,
    isCommunitySuccess: isSuccess,
  };
};
