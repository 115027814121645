import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ActionSheetsType } from '@/models/types';
import { getActionSheets } from '@/services/api';
import useCommunityStore from '@/store/community/community.store';

export const useGetActionSheets = (tags: string[]) => {
  const { communityData } = useCommunityStore();
  return useQuery<ActionSheetsType[], AxiosError>({
    queryKey: ['action-sheets', tags, communityData?.id],
    queryFn: () => getActionSheets(tags, communityData?.id),
    enabled: !!tags.length && !!communityData?.id,
  });
};
