import { NameTrait } from 'src/models/entities/common-types';

export const RoutesPath = {
  Init: '/',
  Login: '/login',
  Magic: '/magic-redirect',
  Dashboard: 'dashboard',
  Maps: 'maps',
  Graphs: 'graphs',
  ActionSheets: 'action-sheets',
  Resources: 'ressources',
  StructureList: 'structure-list',
  Information: 'information',
  ProjectMembers: 'project-members',
  Profile: 'profile',
  Analytics: 'analytics',
};

export const StructureValidationState = {
  accepted: 'accepted',
  waiting: 'waiting',
  canceled: 'canceled',
};

export const getUniqueListBy = <T>(arr: T[] | undefined, key?: string) => {
  return [...new Map(arr?.map((item) => [item?.[key as keyof T], item])).values()];
};

export const filterByValue = <T extends Record<string, unknown>, K extends keyof T>(
  array: T[],
  value: K,
) => {
  return array.some((items) => Object.keys(items).some((item) => items[item as keyof T] === value));
};

export const getDifference = (a: string[], b: string[]) => {
  return a.filter((element) => {
    return !b.includes(element);
  });
};

export const checkName = (
  translate: (arg: NameTrait) => void,
  name: string | NameTrait | null | undefined,
) => (typeof name === 'object' ? translate(name as NameTrait) : (name as string));

export const mapRecursive = <T>(
  key: keyof T,
  oldArray: Array<T & { key?: T[] }>,
  callback: (item: T, index: number) => T,
  newArray: T[] = [],
): T[] => {
  if (oldArray.length <= 0) {
    return newArray;
  } else {
    let [item, ...theRest] = oldArray;
    if (item[key]) {
      //@ts-ignore
      item = { ...item, [key]: mapRecursive<T>(key, item[key], callback) };
    }
    const interimArray = [...newArray, callback(item, newArray.length)];
    return mapRecursive<T>(key, theRest, callback, interimArray);
  }
};

//@ts-ignore
export const findParents = <T>(
  node: T,
  searchValue: string | number | undefined,
  key: string,
  parentKey: string,
  searchKey: string,
) => {
  if (node[searchKey as keyof T] === searchValue) {
    return [];
  }
  if (Array.isArray(node[parentKey as keyof T])) {
    for (let treeNode of node[parentKey as keyof T] as T[]) {
      const childResult: T[keyof T][] | undefined = findParents(
        treeNode,
        searchValue,
        key,
        parentKey,
        searchKey,
      );
      if (Array.isArray(childResult)) {
        return [treeNode[key as keyof T]].concat(childResult);
      }
    }
  }
};

export const containsNumbers = (str: string) => {
  return /[0-9]/.test(str);
};

export const getNumbersFromString = (string: string) => {
  var numsStr = string.replace(/[^0-9]/g, '');
  return parseInt(numsStr);
};
