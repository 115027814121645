import { List, ListItem, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Fragment, useContext, useState } from 'react';

import { WeverCoreContext as Context } from '@/services/core';
import useCommunityStore from '@/store/community/community.store';
import { getMenuTags } from '@/utils/utils';

interface AxeDialogProps {
  axeTag: string;
  actionSheets: { id: string; name: string }[];
  communityId: number;
}

const AxeDialog = ({ axeTag, actionSheets, communityId }: AxeDialogProps) => {
  const [open, setOpen] = useState(false);
  const { communityData } = useCommunityStore();
  const { locale } = useContext<Record<string, string>>(Context);
  const tags = getMenuTags(communityData?.menu!, locale);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const tagName = tags.find((tag) => tag.alias === axeTag)?.name ?? '';

  return (
    <Fragment>
      <div
        onClick={handleClickOpen}
        style={{ cursor: 'pointer', fontWeight: '400', fontSize: '14px' }}
        onMouseOver={(e) =>
          (e.currentTarget.style.color =
            communityData?.dataPrivate.secondaryColor?.value ?? 'black')
        }
        onMouseOut={(e) => (e.currentTarget.style.color = 'inherit')}
      >
        {tagName}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <List>
            {actionSheets?.map((actionSheet) => (
              <ListItem key={actionSheet.id}>
                <Stack
                  component={'a'}
                  style={{ textDecoration: 'none', color: 'black' }}
                  href={`/action-sheets/${actionSheet.id}?community=${communityId}`}
                  target='_blank'
                  onMouseOver={(e) =>
                    (e.currentTarget.style.color =
                      communityData?.dataPrivate.secondaryColor?.value ?? 'black')
                  }
                  onMouseOut={(e) => (e.currentTarget.style.color = 'inherit')}
                >
                  {actionSheet.name}
                </Stack>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default AxeDialog;
