import { Popper, Box, TextField, Input, Button } from '@mui/material';

import { TextItem } from '@/views/poster/components/text-item-editor';

interface EditPopperProps {
  mode: 'add' | 'edit';
  anchorEl: HTMLElement | null;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
  newText?: string;
  setNewText?: (newText: string) => void;
  newFontSize?: number;
  setNewFontSize?: (newFontSize: number) => void;
  addText?: () => void;
  textItem?: TextItem;
  updateTextColor?: (textItemId: string, color: string) => void;
  updateTextContent?: (textItemId: string, content: string) => void;
  updateTextFontSize?: (textItemId: string, fontSize: number) => void;
  updateTextFontWeight?: (textItemId: string, fontWeight: string) => void;
}

const EditTextPopper = ({
  anchorEl,
  setAnchorEl,
  newText,
  setNewText,
  newFontSize,
  setNewFontSize,
  addText,
  textItem,
  updateTextColor,
  updateTextContent,
  updateTextFontSize,
  updateTextFontWeight,
}: EditPopperProps) => {
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const saveText = () => {
    if (!textItem) {
      addText?.();
    }
    setAnchorEl(null);
  };

  return (
    <Popper id={id} open={open} anchorEl={anchorEl}>
      <Box
        sx={{
          border: 1,
          p: 1,
          bgcolor: 'background.paper',
          gap: '15px',
          display: 'flex',
          flexDirection: 'column',
          width: '200px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <TextField
            id='standard-multiline-flexible'
            multiline
            value={textItem ? textItem.text : newText}
            onChange={(e) =>
              textItem
                ? updateTextContent?.(textItem.id, e.target.value)
                : setNewText?.(e.target.value)
            }
            maxRows={4}
            variant='standard'
            placeholder='Entrez le texte'
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            justifyContent: 'space-between',
          }}
        >
          <p>Taille:</p>
          <Input
            type='number'
            value={textItem ? textItem.fontSize : newFontSize}
            onChange={(e) =>
              textItem
                ? updateTextFontSize?.(textItem.id, Number(e.target.value))
                : setNewFontSize?.(Number(e.target.value))
            }
            placeholder='Font size'
            sx={{ width: '50px' }}
          />
        </div>
        {textItem && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'baseline',
              justifyContent: 'space-between',
            }}
          >
            <p>Couleur:</p>
            <input
              type='color'
              value={textItem.color}
              onChange={(e) => updateTextColor?.(textItem.id, e.target.value)}
            />
          </div>
        )}
        {textItem && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'baseline',
              justifyContent: 'space-between',
            }}
          >
            <p>Epaisseur:</p>
            <select
              value={textItem.fontWeight}
              onChange={(e) => updateTextFontWeight?.(textItem.id, e.target.value)}
            >
              <option value='normal'>Normal</option>
              <option value='bold'>Bold</option>
              <option value='bolder'>Bolder</option>
              <option value='lighter'>Lighter</option>
              <option value='100'>100</option>
              <option value='200'>200</option>
              <option value='300'>300</option>
              <option value='400'>400</option>
              <option value='500'>500</option>
              <option value='600'>600</option>
              <option value='700'>700</option>
              <option value='800'>800</option>
              <option value='900'>900</option>
            </select>
          </div>
        )}
        <Button
          variant='outlined'
          style={{ color: 'black', border: '1px solid black', borderRadius: '30px' }}
          onClick={saveText}
        >
          Enregistrer
        </Button>
      </Box>
    </Popper>
  );
};

export default EditTextPopper;
