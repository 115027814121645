import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Community } from '@/models/types';
import { getUserCommunities } from '@/services/api';
import useAuthStore from '@/store/auth/auth.store';

export const useGetUserCommunites = () => {
  const { user } = useAuthStore();
  return useQuery<Community[], AxiosError>({
    queryKey: ['user-communities', user?.id],
    queryFn: () => getUserCommunities(),
  });
};
