/** @jsxImportSource @emotion/react */

import { Container, Stack, Tab } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import React, { Fragment, useContext } from 'react';

import CustomAutocomplete from '@/components/core/common/autocomplete/custom-autocomplete';
import Breadcrumbs from '@/components/core/common/breadcrumbs/breadcrumbs';
import NavTabs from '@/components/core/common/nav-tabs/nav-tabs';
import LoadingLayout from '@/components/layout/loading-layout';
import { useGetActionSheets } from '@/hooks/queries/useGetActionSheets';
import { useCurrentMenu } from '@/hooks/useCurrentMenu';
import { ActionsSheetInterface, Breadcrumb } from '@/models/types';
import { WeverCoreContext as Context } from '@/services/core';
import StatusSelect from '@/views/action-sheets/components/status-select/status-select';
import { actionSheetStyle } from '@/views/action-sheets/single-action-sheet/action-sheet.style';
import DescriptionTab from '@/views/action-sheets/single-action-sheet/components/description/description-tab';
import DashboardTabs from '@/views/action-sheets/single-action-sheet/components/show-dashboard/dashboard-tab';
import { actionsSheetsTabs } from '@/views/action-sheets/single-action-sheet/helpers';
import useSingleActionSheet from '@/views/action-sheets/single-action-sheet/use-single-action-sheet';

const SheetAction = () => {
  const navigate = useNavigate();
  const { currentContent } = useCurrentMenu();
  const { data: actionSheetsData, isLoading: actionSheetsLoading } = useGetActionSheets(
    currentContent?.filterTags ?? [],
  );

  const { locale } = useContext<Record<string, string>>(Context);

  const { actionSheet, activeTab, isLoading, setActiveTab, groupedDataByJourneyType } =
    useSingleActionSheet(actionSheetsData, currentContent!, locale);

  const renderNavItems = actionsSheetsTabs(actionSheet).map(
    (tab, index) => tab.value && <Tab key={index} label={tab.title} />,
  );

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: ActionsSheetInterface | null,
  ) => {
    navigate({
      params: { id: newValue?.id },
      search: true,
    });
  };

  const breadcrumbs: Breadcrumb[] = [
    ...(currentContent?.breadcrumbs ?? []),
    { name: actionSheet?.name ?? '', url: '' },
  ];

  const stateMode =
    groupedDataByJourneyType?.find((item) => item.id === actionSheet?.id)?.alias ?? '';

  const handleChangeTabs = (_event: React.SyntheticEvent<Element, Event>, value: number) => {
    setActiveTab(value);
  };

  if (isLoading || actionSheetsLoading) return <LoadingLayout />;

  return (
    <div css={actionSheetStyle}>
      <div className='single-action-breadcrumbs'>
        <Breadcrumbs items={breadcrumbs} />
      </div>
      <Stack display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
        <div className='action-sheet-select'>
          <CustomAutocomplete<ActionsSheetInterface>
            handleChange={handleChange}
            data={groupedDataByJourneyType}
            defaultValue={actionSheet}
            getOptionLabel={(option) => option.name ?? ''}
            groupBy={(option) => (option.journeyType ? option.journeyType : null)}
            isLoading={actionSheetsLoading}
            loadingIconSize={15}
            renderOption={(props, option) => (
              <li style={{ fontSize: '16px' }} {...props}>
                {option.name}
              </li>
            )}
            size='small'
          />
        </div>
        <Stack pr={8}>
          <StatusSelect
            actionSheetId={actionSheet?.id!}
            actionSheetState={actionSheet?.state ?? null}
            stateMode={stateMode ?? ''}
          />
        </Stack>
      </Stack>
      <Container className='action-sheet' maxWidth='xl'>
        <NavTabs value={activeTab} onChange={handleChangeTabs}>
          {renderNavItems}
        </NavTabs>
        {actionSheet && (
          <Fragment>
            <DescriptionTab actionSheet={actionSheet} tabId={activeTab} />
            {actionSheet.evaluationDashboard && (
              <DashboardTabs
                tabId={activeTab as number}
                dashboardType='evaluation'
                actionSheetId={actionSheet.id}
                dashboard={actionSheet.evaluationDashboard}
                index={1}
              />
            )}

            {actionSheet.dimensionDashboard && (
              <DashboardTabs
                tabId={activeTab}
                dashboardType='operation'
                actionSheetId={actionSheet.id}
                dashboard={actionSheet.dimensionDashboard}
                index={2}
              />
            )}

            {actionSheet.feedbackDashboard && (
              <DashboardTabs
                tabId={activeTab}
                dashboardType='feedback'
                actionSheetId={actionSheet.id}
                dashboard={actionSheet.feedbackDashboard}
                index={3}
              />
            )}
          </Fragment>
        )}
      </Container>
    </div>
  );
};

export default SheetAction;
