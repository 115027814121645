import { css } from '@emotion/react';

export const graphStyle = (isMapView: boolean, windowHeight: number) => css`
    .graph-breadcrumbs {
        padding-left: 15px;
    }
    .graph-select {
        padding-top: 10px;
        padding-left: 15px; 
        width: 400px;
    }
    .graph {
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;

        .dashboardappContainer {
            .row {
                padding: 0;
                .col-12 {
                    padding: 0;
                    flex: 0 0 100%;
                    max-width: 100%;
                    .graphappContainer {
                        border: ${isMapView ? 'none' : '1px solid rgba(0,0,0,0.125)'};
                        .card-body {
                          padding: 0;  
                          .graphapp-map {
                              height: 100%;
                              max-height: 100%;
                              .mapContainer {
                                  height: auto;
                              }
                              .mapsLeaflet {
                                height: ${windowHeight / 1.4}px;
                                max-height: none !important;
                              }
                          }
                        }
                    }
                }
            }
        }
    }
}
`;
