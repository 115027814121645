import { Outlet } from '@tanstack/react-router';
import React, { FC, useState } from 'react';

import LoadingLayout from '@/components/layout/loading-layout';
import AdminNavbar from '@/components/layout/navbars/admin-navbar';
import UserSidebar from '@/components/layout/sidebar/user-sidebar';
import { useLayout } from '@/components/layout/use-layout';
import RedirectRoute from '@/routes/RedirectRoute';
import useCommunityStore from '@/store/community/community.store';

const Layout: FC = () => {
  const { userCommunitesLoading, communityLoading } = useLayout();
  const { communityData } = useCommunityStore();
  const [sidebarOpen, setSideBarOpen] = useState(true);

  if (userCommunitesLoading || communityLoading || !communityData) {
    return <LoadingLayout />;
  }

  return (
    <div style={{ display: 'flex' }}>
      <div>
        <UserSidebar sidebarOpen={sidebarOpen} setSideBarOpen={setSideBarOpen} />
      </div>
      {/*@ts-ignore*/}
      <div style={{ width: '100%', paddingTop: '75px' }}>
        <AdminNavbar />
        <RedirectRoute>
          <Outlet />
        </RedirectRoute>
      </div>
    </div>
  );
};

export default Layout;
