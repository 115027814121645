import Konva from 'konva';
import QRCode from 'qrcode';
import { useState, useRef, useEffect } from 'react';

import { useUploadRessource } from '@/hooks/mutations/useUploadRessource';
import { _Environment } from '@/services/core';
import useCommunityStore from '@/store/community/community.store';
import { ImageItem, TextItem } from '@/views/poster/components/url-image';

const usePoster = () => {
  const [imageURL, setImageURL] = useState<string | null>(null);
  const [scale, setScale] = useState<number>(0.4);
  const [stageSize, setStageSize] = useState<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const stageRef = useRef<Konva.Stage>(null);
  const { communityData } = useCommunityStore();
  const [texts, setTexts] = useState<TextItem[]>([]);
  const [images, setImages] = useState<ImageItem[]>([]);
  const [newText, setNewText] = useState<string>('');
  const [newFontSize, setNewFontSize] = useState<number>(35);
  const [qrCodeContent, setQrCodeContent] = useState<string>('https://example.com');
  const [qrCodeColor, setQrCodeColor] = useState<string>('#000000');
  const [imageName, setImageName] = useState<string | null>(null);
  const [loadedImages, setLoadedImages] = useState<{ [key: string]: HTMLImageElement | null }>({});
  const [addedCommunityData, setAddedCommunityData] = useState<boolean>(false);

  const handleSetStageSize = (width: number, height: number) => {
    setStageSize({ width, height });
  };

  const addText = () => {
    const newTextItem: TextItem = {
      id: `text-${texts.length + 1}`,
      text: newText || 'New Text',
      color: '#000000',
      fontSize: newFontSize,
      isCommunityData: false,
      fontWeight: 'normal',
      x: 100,
      y: 100,
    };
    setTexts([...texts, newTextItem]);
    setNewText('');
    setNewFontSize(35);
  };

  const updateTextColor = (id: string, color: string) => {
    const copyTexts = [...texts];
    const newTexts = copyTexts.map((text) => (text.id === id ? { ...text, color } : text));
    setTexts(newTexts);
  };

  const updateTextContent = (id: string, content: string) => {
    const copyTexts = [...texts];
    const newTexts = copyTexts.map((text) => (text.id === id ? { ...text, text: content } : text));
    setTexts(newTexts);
  };

  const updateTextFontSize = (id: string, size: number) => {
    const copyTexts = [...texts];
    const newTexts = copyTexts.map((text) => (text.id === id ? { ...text, fontSize: size } : text));
    setTexts(newTexts);
  };

  const updateTextFontWeight = (id: string, weight: string) => {
    const copyTexts = [...texts];
    const newTexts = copyTexts.map((text) => (text.id === id ? { ...text, fontWeight: weight } : text));
    setTexts(newTexts);
  };

  const removeImageFromLoadedImages = (id: string) => {
    setLoadedImages((prev) => {
      const newLoadedImages = { ...prev };
      delete newLoadedImages[id];
      return newLoadedImages;
    });
  };

  const deleteText = (id: string) => {
    const copyTexts = [...texts];
    const newTexts = copyTexts.filter((text) => text.id !== id);
    setTexts(newTexts);
  };

  const deleteImage = (id: string) => {
    const copyImages = [...images];
    const newImages = copyImages.filter((image) => image.id !== id);
    removeImageFromLoadedImages(id);
    setImages(newImages);
  };

  const addQRCode = async () => {
    try {
      const qrCodeDataURL = await QRCode.toDataURL(qrCodeContent, {
        color: {
          dark: qrCodeColor,
          light: '#0000',
        },
      });

      const newQRCodeItem: ImageItem = {
        id: `qr-code-${images.length + 1}`,
        src: qrCodeDataURL,
        x: 83.99999999999982,
        y: 624.6457198408591,
        width: 56.2630494670994,
        height: 56.26304946709944,
        isCommunityData: false,
      };

      setImages([...images, newQRCodeItem]);
    } catch (error) {
      console.error('Error generating QR code', error);
    }
  };

  const addCommunityData = async () => {
    const qrCodeDataURL = await QRCode.toDataURL(communityData?.url || 'Community URL', {
      color: {
        dark: qrCodeColor,
        light: '#0000',
      },
    });

    setTexts([
      ...texts,
      {
        id: 'community-url',
        text: communityData?.url || 'Community URL',
        color: '#d73755',
        fontSize: 35,
        isCommunityData: true,
        fontWeight: 'normal',
        x: 158,
        y: 651.3422119281993,
      },
    ]);
    setImages([
      ...images,
      {
        id: 'community-logo',
        src: `https://corsproxy.io/?${encodeURIComponent(_Environment.get('api_url'))}/media/${
          communityData?.logo?.filePath
        }`,
        x: 215.99999999999991,
        y: 19.982628836131965,
        isCommunityData: true,
        width: 82,
        height: 74,
      },
      {
        id: `qr-code-${images.length + 1}`,
        src: qrCodeDataURL,
        x: 83.99999999999982,
        y: 624.6457198408591,
        width: 56.2630494670994,
        height: 56.26304946709944,
        isCommunityData: true,
      },
    ]);
    setAddedCommunityData(true);
  };

  const checkCommunityData = () => {
    const hasCommunityText = texts.some(text => text.isCommunityData);
    const hasCommunityImage = images.some(image => image.isCommunityData);
    if (!hasCommunityText && !hasCommunityImage) {
      setAddedCommunityData(false);
    }
  };

  useEffect(() => {
    checkCommunityData();
    // Disable the eslint rule for exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [texts, images]);

  const { mutate: uploadRessource, isPending: uploadLoading } = useUploadRessource();

  const downloadStageAsImage = () => {
    if (stageRef.current && imageURL) {
      const stage = stageRef.current;
      const originalWidth = stage.width();
      const originalHeight = stage.height();

      const img = new Image();
      img.src = imageURL;
      img.onload = () => {
        const newWidth = img.width;
        const newHeight = img.height;

        const scaleX = newWidth / originalWidth;
        const scaleY = newHeight / originalHeight;

        stage.scale({ x: scaleX, y: scaleY });
        stage.size({ width: newWidth, height: newHeight });
        stage.batchDraw();

        const uri = stage.toDataURL({ mimeType: 'image/png', quality: 1 });

        stage.scale({ x: 1, y: 1 });
        stage.size({ width: originalWidth, height: originalHeight });
        stage.batchDraw();

        const link = document.createElement('a');
        link.download = `${imageName}.png`;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    }
  };

  const saveImage = () => {
    if (stageRef.current && imageURL) {
      const stage = stageRef.current;
      const originalWidth = stage.width();
      const originalHeight = stage.height();

      const img = new Image();
      img.src = imageURL;
      img.onload = () => {
        const newWidth = img.width;
        const newHeight = img.height;

        const scaleX = newWidth / originalWidth;
        const scaleY = newHeight / originalHeight;

        stage.scale({ x: scaleX, y: scaleY });
        stage.size({ width: newWidth, height: newHeight });
        stage.batchDraw();

        const uri = stage.toDataURL({ mimeType: 'image/png', quality: 1 });

        stage.scale({ x: 1, y: 1 });
        stage.size({ width: originalWidth, height: originalHeight });
        stage.batchDraw();

        const blob = dataURItoBlob(uri);
        const formData = new FormData();
        formData.append('file', blob, `${imageName}.png`);
        uploadRessource({ formData, filename: `${imageName}.png` });
      };
    }
  };

  // Helper function to convert data URI to Blob
  const dataURItoBlob = (dataURI: string) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  return {
    imageURL,
    setImageURL,
    scale,
    setScale,
    stageSize,
    setStageSize,
    stageRef,
    texts,
    setTexts,
    images,
    setImages,
    newText,
    setNewText,
    newFontSize,
    setNewFontSize,
    qrCodeContent,
    setQrCodeContent,
    qrCodeColor,
    setQrCodeColor,
    handleSetStageSize,
    addText,
    updateTextColor,
    updateTextContent,
    updateTextFontSize,
    updateTextFontWeight,
    addQRCode,
    addCommunityData,
    downloadStageAsImage,
    deleteText,
    deleteImage,
    imageName,
    setImageName,
    uploadLoading,
    saveImage,
    loadedImages,
    setLoadedImages,
    addedCommunityData,
  };
};

export default usePoster;
