import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';

import { ActionSheetState } from '@/models/entities/common-types';
import { updateActionSheetState } from '@/services/api';
import useCommunityStore from '@/store/community/community.store';

export const useUpdateActionSheetStatus = () => {
  const queryClient = useQueryClient();
  const { communityData } = useCommunityStore();

  return useMutation<
    { succes: boolean; message: string },
    AxiosError,
    { state: ActionSheetState; actionSheetId: number; stateMode: string }
  >({
    mutationFn: (data) =>
      updateActionSheetState(data.actionSheetId, communityData!.id, data.state, data.stateMode),
    onMutate: () => {
      toast.loading('Mise à jour de la fiche action en cours...', {
        style: {
          padding: '8px',
          color: 'white',
          fontWeight: 'bold',
          fontSize: '14px',
          backgroundColor: communityData?.dataPrivate
            ? communityData?.dataPrivate.primaryColor?.value
            : 'black',
        },
      });
    },
    onSuccess: () => {
      toast.dismiss();
      toast.success('Fiche action mise à jour avec succès', {
        style: {
          padding: '8px',
          color: 'white',
          fontWeight: 'bold',
          fontSize: '14px',
          backgroundColor: communityData?.dataPrivate
            ? communityData?.dataPrivate.primaryColor?.value
            : 'black',
        },
      });
      queryClient.invalidateQueries({ queryKey: ['action-sheets'] });
      queryClient.invalidateQueries({ queryKey: ['childrenCommunities'] });
    },
    onError: () => {
      toast.dismiss();
      toast.error('Echec de la mise à jour de la fiche action');
    },
  });
};
