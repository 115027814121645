import { List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import React from 'react';
import { FaRegTrashCan } from 'react-icons/fa6';

import { ImageItem } from '@/views/poster/components/url-image';

interface ImagesListProps {
  images: ImageItem[];
  deleteImage: (id: string) => void;
}

const ImagesList = ({ images, deleteImage }: ImagesListProps) => {
  return (
    images.length > 0 && (
      <div style={{ paddingTop: '10px', paddingLeft: '15px' }}>
        <Stack component={Typography} variant='h6'>
          Images
        </Stack>
        <List dense>
          {images.map((imageItem) => (
            <React.Fragment key={imageItem.id}>
              <ListItem>
                <ListItemText sx={{ cursor: 'pointer' }} primary={imageItem.id} />
                <ListItemIcon sx={{ cursor: 'pointer' }} onClick={() => deleteImage(imageItem.id)}>
                  <FaRegTrashCan />
                </ListItemIcon>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </div>
    )
  );
};

export default ImagesList;
