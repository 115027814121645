import { css } from '@emotion/react';

export const actionSheetStyle = css`
  .action-sheet-breadcrumbs {
    padding-top: 20px;
  }

  .single-action-breadcrumbs {
    padding-left: 15px;
  }

  .action-sheet-select {
    padding-top: 10px;
    padding-left: 15px;
    width: 400px;
  }

  .action-sheet {
    padding-top: 45px;
  }
`;
