import { css } from '@emotion/react';

import { DataPrivate } from '@/models/entities/common-types';

export const breadcrumbsStyle = (dataPrivate?: DataPrivate) => css`
  cursor: pointer;
  .custom-breadcrumb {
    .MuiBreadcrumbs-separator {
      color: ${dataPrivate?.secondaryColor?.value ?? '#d73755'};
      font-size: 16px;
      font-weight: 400;
      font-family: 'Questrial';
    }
  }
`;

export const breadcrumbsItemStyle = (isLast?: boolean, dataPrivate?: DataPrivate) => css`
    color: ${dataPrivate?.secondaryColor?.value ?? '#d73755'};
    font-size: 16px;
    font-weight: ${!isLast ? '400' : 'bold'};
    font-family: ${!isLast ? 'Questrial' : 'Helvetica'};
    text-decoration: underline;
    &:hover {
      color: ${dataPrivate?.secondaryColor?.value ?? '#d73755'};
    }
  }
`;
