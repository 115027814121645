import { css } from '@emotion/react';

export const structuresTableStyle = () => css`
  overflow: hidden;
  width: 100%;

  .custom-table-container {
    .custom-table-header-cell {
      font-size: 16px;
      font-family: 'Helvetica', sans-serif;
      font-weight: bold;
      color: #183d4c;
    }

    .custom-table-body-cell {
      font-size: 14px;
      font-family: 'Helvetica', sans-serif;
      font-weight: 200;
      color: #183d4c;
    }

    .structure-list-uptate-button {
      cursor: pointer;
      background-color: #d73755;
      border-radius: 25px 25px 25px 25px;
      text-align: center;
      color: white;
      padding-left: 15px;
      padding-right: 15px;
      font-family: 'Helvetica', sans-serif;
      font-size: 12px;
      font-weight: 400;
    }
    .accepted {
      background-color: #c4d637;
      cursor: none;
    }
  }
`;
