import { css } from '@emotion/react';

export const ressourcesStyle = css`
  .ressources-content {
    padding-top: 50px;

    .structure-error {
      text-align: center;
    }

    .collapse-box {
      padding-top: 40px;

      .ressources-list-table-container {
        padding-top: 10px;
        .ressources-list-table {
          width: 100%;
          th {
            text-align: left;
            padding: 8px;

            span {
              font-size: 16px;
              font-family: 'Roboto', sans-serif;
              font-weight: 200;
              color: #9b9b9b;
            }
          }
          .table-line {
            border-top: 2px solid #9b9b9b;
            border-bottom: none;

            .title {
              font-size: 18px;
              font-family: 'Roboto', sans-serif;
              font-weight: 200;
              color: #183d4c;

              img {
                width: 5%;
                margin-right: 5px;
              }
            }
            td {
              text-align: left;
              padding: 8px;

              span {
                font-size: 12px;
                font-family: 'Roboto', sans-serif;
                font-weight: 200;
                color: #9b9b9b;
              }
            }
          }
        }
      }
    }
  }
`;
