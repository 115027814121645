import { css } from '@emotion/react';

export const languageSelectStyle = css`
  padding-right: 10px;
  .language-select {
    .MuiSelect-select {
      span {
        font-size: 18px;
        font-family: 'Questrial';
        color: #183d4c;
        padding-right: 10px;
      }
    }

    svg {
      color: #183d4c;
      font-size: 30px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
`;
