import { ListItemText, MenuItem } from '@mui/material';
import React, { ChangeEvent, useRef } from 'react';

import { ImageItem } from '@/views/poster/components/url-image';

interface ImageUploaderProps {
  setImageURL: (url: string) => void;
  setImages: (images: ImageItem[]) => void;
  images: ImageItem[];
  imageURL: string | null;
  handleClickOpen: () => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  setImageURL,
  setImages,
  images,
  imageURL,
  handleClickOpen,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const otherFileInputRef = useRef<HTMLInputElement>(null);

  const resetInput = (inputRef: React.RefObject<HTMLInputElement>) => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target && typeof event.target.result === 'string') {
          setImageURL(event.target.result);
        }
      };
      reader.readAsDataURL(file);
      resetInput(fileInputRef);
    }
  };

  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target && typeof event.target.result === 'string') {
          const newImageItem: ImageItem = {
            id: `image-${images.length + 1}`,
            src: event.target.result,
            x: 100,
            y: 100,
            width: 200,
            height: 200,
            isCommunityData: false,
          };
          setImages([...images, newImageItem]);
          resetInput(otherFileInputRef);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <input
        type='file'
        accept='image/*'
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <input
        type='file'
        accept='image/*'
        ref={otherFileInputRef}
        onChange={handleImageUpload}
        style={{ display: 'none' }}
      />
      <MenuItem sx={{ cursor: 'pointer' }} onClick={() => fileInputRef.current?.click()}>
        <ListItemText>Ajouter une image de fond</ListItemText>
      </MenuItem>
      <MenuItem sx={{ cursor: 'pointer' }} onClick={handleClickOpen}>
        <ListItemText>Choisir un template</ListItemText>
      </MenuItem>
      {imageURL && (
        <MenuItem sx={{ cursor: 'pointer' }} onClick={() => otherFileInputRef.current?.click()}>
          <ListItemText>Ajouter une image</ListItemText>
        </MenuItem>
      )}
    </div>
  );
};

export default ImageUploader;
