/** @jsxImportSource @emotion/react */

// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Drawer } from '@mui/material';
import React from 'react';

import circleIcon from '@/assets/img/brand/circle-icon.svg';
import logo from '@/assets/img/brand/logo.png';
import downLogo from '@/assets/img/icons/common/gris.svg';
import UserCommunities from '@/components/core/communities/community-select/user-communities';
import { sidebarMenuStyle } from '@/components/layout/sidebar/sidebar.style';
import UserSidebarMenu from '@/components/layout/user-menu/user-sidebar-menu';
// import { _translate as t } from '@/services/core';
import useCommunityStore from '@/store/community/community.store';

interface UserSidebarProps {
  sidebarOpen: boolean;
  setSideBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserSidebar = ({ sidebarOpen, setSideBarOpen }: UserSidebarProps) => {
  const { communityData } = useCommunityStore();
  const communityLogo = communityData?.logo;

  const newlogo = communityLogo?.path ?? logo;
  const thumbnailLogo = communityLogo?.path ?? circleIcon;

  return (
    <Drawer variant='permanent' open={sidebarOpen} css={sidebarMenuStyle(sidebarOpen)}>
      <div className='logo'>
        <img alt='logo' src={sidebarOpen ? newlogo : thumbnailLogo} width='100px' />
      </div>
      <div className='community-select'>
        {<UserCommunities sidebarOpen={sidebarOpen} />}
        <hr />
      </div>
      <div className='sidebar-menu'>
        {<UserSidebarMenu sidebarOpen={!!sidebarOpen} setSideBarOpen={setSideBarOpen} />}
      </div>
      {sidebarOpen && (
        <img className='down-logo' src={downLogo} width='100%' height='auto' alt='banner' />
      )}
      {/* <div className='toggle' onClick={() => setSideBarOpen(!sidebarOpen)}>
        {!sidebarOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        {sidebarOpen && <span>{t('manager_app_close_sidebar')}</span>}
      </div> */}
    </Drawer>
  );
};

export default UserSidebar;
