import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { DashboardLayoutType } from '@/models/types';
import { getDashboardsLayout } from '@/services/api';

export const useGetDashboardsLayout = (tags: string[]) => {
  return useQuery<DashboardLayoutType[], AxiosError>({
    queryKey: ['dashboard-layout', tags],
    queryFn: () => getDashboardsLayout(tags),
    enabled: !!tags.length,
  });
};
