import { ActionSheetState, DataPrivate } from '@/models/entities/common-types';
import { css } from '@emotion/react';

export const statusSelectStyle = (status: ActionSheetState | null, dataPrivate?: DataPrivate, bgColor?: string) => css`
  width: 200px;
  .status-select {
    border-radius: 30px;
    height: 35px;
    text-align: center;
    background-color: ${bgColor ?? "white"};
  }

  .MuiOutlinedInput-notchedOutline {
    border: ${status ? "none" : "1px solid #E0E0E0"};
    border-color: black !important;
  }
`;