/** @jsxImportSource @emotion/react */

import { ListItem, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import { Fragment, useContext, useState } from 'react';

import { menuIcons } from '@/components/layout/user-menu/helpers';
import { MenuItem, SearchParams } from '@/models/types';
import { WeverCoreContext, _translate as t } from '@/services/core';
import useCommunityStore from '@/store/community/community.store';
import { findAllChildrenIds } from '@/utils/utils';

import { userSidebarMenuStyle, userSidebarMenuSubContent } from './user-sider-menu.style';

interface UserSidebarMenuProps {
  sidebarOpen: boolean;
  setSideBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserSidebarMenu = ({ sidebarOpen, setSideBarOpen }: UserSidebarMenuProps) => {
  const { locale }: { locale: string } = useContext(WeverCoreContext);

  const { communityData } = useCommunityStore();
  const navigate = useNavigate();
  const firstMenuItemChildrenIds = communityData?.menu
    ? findAllChildrenIds(communityData.menu[0]?.menu ?? [])
    : [];

  const [expandedItems, setExpandedItems] = useState<number[]>([
    communityData!.menu[0].id,
    ...firstMenuItemChildrenIds,
  ]);

  const newMenu = communityData?.menu;
  const dataPrivate = communityData?.dataPrivate;

  const parentsIds = communityData?.menu
    .map((item) => (item.menu && item.menu.length > 0 ? item.id : undefined))
    .filter((id): id is number => typeof id === 'number');

  const handleUrl = (item: MenuItem) => {
    if (item.url) {
      navigate({
        to: item.url,
        search: (prev: SearchParams): SearchParams => ({
          ...prev,
          community: communityData?.id,
          menu: item.id,
          content: item.newContent?.[0].id,
        }),
      });
    }
  };

  const toggleAccordion = (id: number) => {
    setExpandedItems?.((prev) =>
      prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id],
    );
  };
  const isHighlight = (item: MenuItem) =>
    expandedItems?.includes(item.id) && parentsIds?.includes(item.id);

  const getMenuIcon = (item: MenuItem | undefined) =>
    menuIcons.find((menu) => menu.title === item?.title);

  const menuItemContent = (item: MenuItem): string =>
    typeof item.name === 'string' ? t(item.name) : item.name?.[locale];

  const getFirstLetter = (item: MenuItem): string => {
    const content = menuItemContent(item!);
    console.log(content);

    return 'T';
  }

  const renderSubMenuItems = (items: MenuItem[], parentIndex: number, paddingLeft = 35) =>
    items?.map((item, index) => (
      <Fragment key={index}>
        <div
          className='sub-content'
          css={userSidebarMenuSubContent(item, sidebarOpen)}
          key={index}
          style={{ paddingLeft }}
          onClick={() => handleUrl(item)}
        >
          <ListItemText
            onClick={() => toggleAccordion(item.id)}
            className='list-item-text'
            primary={menuItemContent(item)}
          />
        </div>
        {item.menu && sidebarOpen && (
          <Collapse in={expandedItems?.includes(item.id)}>
            {renderSubMenuItems(item.menu, index, paddingLeft + 20)}
          </Collapse>
        )}
      </Fragment>
    ));

  return (
    <Fragment>
      {newMenu &&
        newMenu?.map((item: MenuItem | undefined, index: number) => {
          let SvgComponent = getMenuIcon(item)?.icon as React.ElementType;
          return (
            <div
              key={index}
              css={userSidebarMenuStyle(item, sidebarOpen, isHighlight(item!)!, dataPrivate)}
            >
              {index === 1 && <hr />}
              <div
                onClick={() => {
                  item && handleUrl(item);
                }}
              >
                <ListItem
                  className='list-item'
                  key={index}
                  disablePadding
                  onClick={() => toggleAccordion(item!.id)}
                >
                  <ListItem className='list-button'>
                    {!getMenuIcon(item)?.displayIcon && sidebarOpen && (
                      <div className='rectangle-icon'></div>
                    )}
                    <ListItemIcon className='list-item-icon'>
                      {getMenuIcon(item)?.displayIcon && <SvgComponent className='svg-component' />}
                      {!getMenuIcon(item)?.icon && !sidebarOpen ? getFirstLetter(item!) : ''}
                    </ListItemIcon>
                    <ListItemText className='list-item-text' primary={menuItemContent(item!)} />
                  </ListItem>
                </ListItem>
              </div>
              {item?.menu && sidebarOpen && (
                <Collapse in={expandedItems?.includes(item.id)}>
                  {renderSubMenuItems(item.menu, index)}
                </Collapse>
              )}
            </div>
          );
        })}
    </Fragment>
  );
};

export default UserSidebarMenu;
