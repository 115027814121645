import { css } from '@emotion/react';

import { DataPrivate } from '@/models/entities/common-types';

export const tabsStyle = (dataPrivate?: DataPrivate) => css`
  border-bottom: solid #f2f2f2;

  .custom-tabs {
    .MuiTab-root {
      color: #c7c7c7;
      font-family: 'Questrial', sans-serif;
      font-size: 25px;
      text-transform: initial;
    }

    .Mui-selected {
      color: ${dataPrivate?.primaryColor?.value ?? '#00aad7'};
      font-family: 'Questrial', sans-serif;
      font-size: 25px;
      text-transform: initial;
    }
  }

  .MuiTabs-indicator {
    background-color: ${dataPrivate?.primaryColor?.value ?? '#00aad7'};
    height: 4px;
  }
`;

export const tabPanelStyle = css`
  padding: 30px;
`;
