import { create } from 'zustand';

import { Community } from '@/models/types';

interface CommunityStore {
  communityData: Community | undefined;
  setCommunityData: (communityData: Community | undefined) => void;
  communityLoading: boolean;
  setCommunityLoading: (communityLoading: boolean) => void;
}

const useCommunityStore = create<CommunityStore>((set) => ({
  communityData: undefined,
  setCommunityData: (communityData) => set({ communityData }),
  communityLoading: false,
  setCommunityLoading: (communityLoading) => set({ communityLoading }),
}));

export default useCommunityStore;
