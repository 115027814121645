/* eslint-disable react-hooks/exhaustive-deps */
import { Switch, Button } from '@mui/material';
import React, { useState, useEffect, ChangeEvent } from 'react';

import { _Icon as Icon } from '@/components/core/common';
import { Column } from '@/components/core/common/table/custom-table';
import CopilMemberInterface from '@/models/entities/copil-member-interface';
import { _translate as t } from '@/services/core';

import ColumnBuilder from './builders/columns-builder';

export interface RowDataProps {
  [key: string]: string | number | React.ReactNode | undefined;
  fullname?: string;
  email?: string;
  job?: string;
  referent?: React.ReactNode;
  actions?: React.ReactNode;
}

export interface CopilMembersProps {
  data: CopilMemberInterface[];
  isReferent?: boolean;
  onDelete: (index: number) => void;
  onUpdate: (form: CopilMemberInterface, index: number | null) => void;
}

const useMember = ({ data, isReferent = false, onDelete, onUpdate }: CopilMembersProps) => {
  const [rows, setRows] = useState<RowDataProps[]>([]);
  const [memberToEdit, setMemberToEdit] = useState<number | null>(null); // index
  const columns: Column[] = ColumnBuilder(isReferent);

  const handleAdd = async (member: CopilMemberInterface) => {
    await onUpdate(member, null);
  };

  const switchReferent = (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
    member: CopilMemberInterface,
  ) => {
    member['referent'] = e.target.checked;
    onUpdate(member, index);
  };

  const activateEdition = (index: number) => {
    setMemberToEdit(index);
  };

  /**
   * Création de l'affichage  d'où le type : RowDataProps
   */
  const initializeRows = () => {
    const tmpRows: RowDataProps[] = [];

    data.forEach((member: CopilMemberInterface, index) => {
      const { lastname, firstname, job, email, referent } = member;

      if (isReferent && !referent) {
        return;
      }

      let copilInformation = {
        fullname: `${firstname || ''} ${lastname ? lastname[0].toUpperCase() + '.' : ''}` || '',
        email: email,
        job: job,
      };

      if (!isReferent) {
        copilInformation['referent'] = (
          <Switch
            checked={referent}
            onChange={(e: ChangeEvent<HTMLInputElement>) => switchReferent(e, index, member)}
          />
        );

        copilInformation['actions'] = !isReferent && (
          <div>
            <Button
              className='bg-transparent border-0 text-danger text-lowercase'
              onClick={() => activateEdition(index)}
            >
              <Icon name='edit' className='mr-1 ' />{' '}
              <span className='fs-6'>{t('manager_app_edit')}</span>
            </Button>
            <Button
              className='bg-dark-pink text-white btn-square-1'
              onClick={() => onDelete(index)}
            >
              <Icon name='trash' className='fs-small-1' />
            </Button>
          </div>
        );
      }

      tmpRows.push(copilInformation);
    });

    setRows(tmpRows);
  };

  useEffect(() => {
    if (!data || !Array.isArray(data)) {
      return;
    }

    initializeRows();
  }, [data]);

  return {
    rows,
    columns,
    activeUpdate: false,
    memberToEdit,
    setMemberToEdit,
    handleAdd,
  };
};

export default useMember;
