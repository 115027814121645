import { Container, Tab } from '@mui/material';

import Loading from '@/components/common/loading';
import Breadcrumbs from '@/components/core/common/breadcrumbs/breadcrumbs';
import NavTabs from '@/components/core/common/nav-tabs/nav-tabs';
import TabPanel from '@/components/core/common/nav-tabs/tab-panel';
import { useCurrentMenu } from '@/hooks/useCurrentMenu';
import { _translate as t } from '@/services/core';

import Members from './members/members';
import useProjectMembers from './use-project-members';

const ProjectMembers = () => {
  const { activeTab, isLoading, copilMembers, setActiveTab, updateMember, deleteMember } =
    useProjectMembers();

  const { currentMenu } = useCurrentMenu();

  return (
    <div>
      <Container className='ressources-container' maxWidth='xl'>
        <Breadcrumbs items={currentMenu?.breadcrumbs} />
        <div className='ressources-content'>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <NavTabs value={activeTab} setValue={setActiveTab}>
                <Tab label={t('manager_app_info_members_copil')} />
                <Tab label={t('manager_app_info_members_referent')} />
              </NavTabs>
              <TabPanel value={activeTab} index={0}>
                <div className='collapse-box'>
                  <Members
                    data={copilMembers}
                    isReferent={false}
                    onUpdate={updateMember}
                    onDelete={deleteMember}
                  />
                </div>
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <div className='collapse-box'>
                  <Members
                    data={copilMembers}
                    isReferent={true}
                    onUpdate={updateMember}
                    onDelete={deleteMember}
                  />
                </div>
              </TabPanel>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default ProjectMembers;
