/** @jsxImportSource @emotion/react */
import EmailIcon from '@mui/icons-material/Email';
import { FormGroup, InputAdornment, TextField } from '@mui/material';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { FormFeedback, Spinner } from 'reactstrap';
import * as Yup from 'yup';

import { loginFormStyle } from '@/components/core/security/login/login-form.style';
import {
  _Notifications as notifications,
  _translate as t,
  _Api as api,
  _Translator as translator,
} from '@/services/core';

interface MagicLoginProps {
  setMagicLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

const MagicLoginForm = ({ setMagicLogin }: MagicLoginProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async ({ username }: { username: string }) => {
    setLoading(true);

    const host = `${window.location.protocol}//${window.location.hostname}`;

    api
      .post('/users/magic_token/generate', { username, host })
      .then(() => notifications.success(t('success'), t('success_magic_link')))
      .catch(() => notifications.error(t('error'), t('problem_magic_link')))
      .finally(() => setLoading(false));
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ username: '' }}
      validationSchema={Yup.object({
        username: Yup.string().required(translator.translate('field_required')),
      })}
    >
      {({ handleSubmit, handleBlur, errors, touched, values, handleChange }) => (
        <form noValidate onSubmit={handleSubmit} css={loginFormStyle}>
          <FormGroup className='mb-5'>
            <TextField
              fullWidth
              id='username'
              name='username'
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.username) && touched.username}
              type='email'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <EmailIcon className='person-icon' />
                  </InputAdornment>
                ),
              }}
            />
            {touched.username && <FormFeedback>{errors.username}</FormFeedback>}
          </FormGroup>
          <div className='text-center'>
            {loading ? (
              <Spinner className='form-spinner' />
            ) : (
              <button className='submit-button w-100' type='submit'>
                {t('managerapp_submit_magic_key')}
              </button>
            )}
          </div>
          <div className='bottom-title'>
            <span onClick={() => setMagicLogin(false)} className='forgot-password'>
              {t('back_to_login')}
            </span>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default MagicLoginForm;
