/** @jsxImportSource @emotion/react */

import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import {
  Checkbox,
  Container,
  FormControlLabel,
  IconButton,
  Paper,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { FC, useState } from 'react';
import { FaArrowUpRightFromSquare } from 'react-icons/fa6';

import { Column, CustomTable } from '@/components/core/common/table/custom-table';
import LoadingLayout from '@/components/layout/loading-layout';
import { CommunityChildren } from '@/models/types';
import { _Api } from '@/services/core';
import { _translate as t, _Environment } from '@/services/core';
import useCommunityStore from '@/store/community/community.store';
import { communityTableStyle } from '@/views/homepage/components/community-homepage/community-table.style';
import AxeDialog from '@/views/homepage/components/community-homepage/components/axe-dialog';

interface RowData {
  [key: string]: string | number | React.ReactNode | undefined;
  name: React.ReactNode;
  createdAt: string;
  zone: string;
  capacity: number;
  diagnostics: React.ReactNode;
  actions: React.ReactNode;
}

const CommunityHomepage: FC = () => {
  const { communityData } = useCommunityStore();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getChildrenCommunities = async () => {
    const communityResponse = await _Api.get(`/managerapp/community/children/${communityData?.id}`);
    return communityResponse.data;
  };

  const { data, isLoading } = useQuery<CommunityChildren[]>({
    queryKey: ['childrenCommunities', communityData?.id],
    queryFn: getChildrenCommunities,
  });

  const columns: Column[] = [
    {
      id: 'name',
      label: t('manager_app_community_name'),
      minWidth: 170,
      display: true,
      align: 'center',
    },
    {
      id: 'createdAt',
      label: t('manager_app_community_creation_date'),
      minWidth: 170,
      display: true,
      align: 'center',
    },
    {
      id: 'zone',
      label: t('manager_app_community_zone'),
      minWidth: 170,
      display: true,
      align: 'center',
    },
    {
      id: 'capacity',
      label: t('manager_app_community_capacity'),
      minWidth: 170,
      align: 'center',
      display: true,
    },
    {
      id: 'participation',
      label: t('manager_app_community_participation'),
      minWidth: 170,
      align: 'center',
      display: true,
    },
    {
      id: 'diagnostics',
      label: t('manager_app_access_diagnostic'),
      minWidth: 170,
      align: 'center',
      display: true,
    },
    {
      id: 'actionSheets',
      label: t('manager_app_accepted_axes'),
      minWidth: 170,
      align: 'center',
      display: true,
    },
    {
      id: 'actions',
      label: t('manager_app_access_ressources'),
      minWidth: 20,
      align: 'center',
      display: true,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(columns);

  const handleColumnVisibilityChange = (columnId: string, isChecked: boolean) => {
    setVisibleColumns((prev) => {
      const columnIndex = columns.findIndex((column) => column.id === columnId);
      if (isChecked) {
        const columnToAdd = columns[columnIndex];
        if (!prev.find((column) => column.id === columnId)) {
          return [...prev.slice(0, columnIndex), columnToAdd, ...prev.slice(columnIndex)].filter(
            (col, index, self) => index === self.findIndex((t) => t.id === col.id),
          );
        }
        return prev;
      } else {
        return prev.filter((column) => column.id !== columnId);
      }
    });
  };

  const rows = data?.map((community) => ({
    name: (
      <Stack
        component={'a'}
        style={{ textDecoration: 'none', color: 'black' }}
        href={`/?community=${community.id}`}
        target='_blank'
      >
        {' '}
        {community.name}
      </Stack>
    ),
    createdAt: community.createdAt ? moment(community.createdAt).locale('fr').format('L') : '',
    zone: community.site.address?.city ?? community.site.addressInformationSheet?.city ?? '',
    capacity: community.totalCapacity,
    participation: community.participation,
    diagnostics: community.diagnostics.map((diagnostic) => (
      <Stack display='flex' flexDirection='row' alignItems='center' gap={1} justifyContent='center'>
        <Stack
          component={'a'}
          style={{ textDecoration: 'none', color: 'black' }}
          href={`${_Environment.get('diag_url')}/?diag=${diagnostic}`}
          target='_blank'
        >
          {diagnostic}
        </Stack>
        <Stack>
          <FaArrowUpRightFromSquare />
        </Stack>
      </Stack>
    )),
    actionSheets: (
      <div>
        {community.acceptedActionSheets.map((axe, index) => (
          <AxeDialog
            key={index}
            axeTag={axe.tag}
            actionSheets={axe.actionSheets}
            communityId={community.id}
          />
        ))}
      </div>
    ),
    actions: (
      <Button className='community-button' sx={{ textTransform: 'none' }} variant='text'>
        <Stack
          component={'a'}
          style={{ textDecoration: 'none', color: 'white' }}
          href={`/ressources?community=${community.id}`}
          target='_blank'
        >
          {' '}
          {t('manager_app_community_ressources_access')}
        </Stack>
      </Button>
    ),
  }));

  if (isLoading) {
    return <LoadingLayout />;
  }

  return (
    <Container maxWidth='xl'>
      <Stack spacing={3}>
        <Stack display='flex' flexDirection='row' pt='15px' alignItems='center'>
          <Stack fontSize='18px' fontFamily='Roboto' fontWeight='600' component={Typography}>
            {t('manager_app_communities_total')}:
          </Stack>
          <Stack
            pl='5px'
            fontSize='25px'
            fontWeight='600'
            color={communityData?.dataPrivate.primaryColor?.value}
            component={Typography}
          >
            {' '}
            {data?.length}
          </Stack>
        </Stack>
        <Stack
          fontSize='18px'
          fontFamily='Roboto'
          fontWeight='600'
          component={Typography}
          display='flex'
          flexDirection='row'
          alignItems='center'
          spacing={3}
          gap={1}
        >
          {t('manager_app_community_referencement')}:{' '}
          <a
            style={{ color: communityData?.dataPrivate.primaryColor?.value }}
            href={`https://register.yeswever.com/?key=${communityData?.key}`}
            target='_blank'
            rel='noreferrer'
          >
            {t('manager_app_community_ressources_access')}
          </a>
        </Stack>
        <Stack fontSize='18px' fontFamily='Roboto' fontWeight='600' component={Typography}>
          {t('manager_app_communities_list')}
        </Stack>
        <Stack>
          <div>
            <Tooltip title={t('manager_app_filter_columns')} placement='top'>
              <IconButton aria-describedby={id} onClick={handleClick}>
                <ViewWeekIcon />
              </IconButton>
            </Tooltip>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Stack p='10px'>
                {columns.map((column) => (
                  <div key={column.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) =>
                            handleColumnVisibilityChange(column.id, e.target.checked)
                          }
                        />
                      }
                      label={column.label}
                      checked={!!visibleColumns.find((c) => c.id === column.id)}
                    />
                  </div>
                ))}
              </Stack>
            </Popover>
          </div>
          <Stack component={Paper} css={communityTableStyle} width='100%' overflow='hidden'>
            <CustomTable<RowData>
              columns={visibleColumns.filter((column) => column.display)}
              rows={rows ?? []}
            />
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

export default CommunityHomepage;
