import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

import View from '@/components/view';
import { _translate as t } from '@/services/core';

interface ErrorProps {
  code: number | string;
  title: string;
  description: string;
}

export const Error = ({ code, title, description }: ErrorProps) => (
  <View>
    <div id='error-page'>
      <Container fluid className='text-center mt--7'>
        <div className='text-center'>
          <h1 className='text-white'>{code}</h1>
        </div>
        <div className='content'>
          <h2>{t(title)}</h2>
          <p>{t(description)}</p>
          <Link to='/'>{t('back_to_homepage')}</Link>
        </div>
      </Container>
    </div>
  </View>
);

/**
 * Page not found
 */
export const NotFound = () => (
  <Error code='404' title='content_not_found' description='content_not_found_description' />
);

/**
 * Page denied
 */
export const AccessDenied = () => (
  <Error code='403' title='access_denied' description='access_denied_description' />
);
