import { css } from '@emotion/react';

export const navbarStyle = css`
  display: flex;
  background-color: white;

  .navbar-app {
    background-color: white;
    box-shadow: none;
    border-bottom: solid #f2f2f2;

    .toolbar-app {
      .space-div {
        flex-grow: 8 !important;
      }
      .content {
        display: flex;
        align-items: center;
      }
    }
  }
`;
