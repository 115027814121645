/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-mutable-exports */
import { _Api as api } from '@/services/core'
import { createBrowserHistory } from 'history'

/**
 * Build an history accessible anywhere
 *
 * @type {object}
 */
const history = createBrowserHistory()

/**
 * Store previous uri of user navigation
 *
 * @type {string}
 */
export let previousUri = '/'

/**
 * Store current uri
 *
 * @type {string}
 */
export let currentUri = history.location.pathname

/**
 * Listen history changes to make actions between page transitions
 */
history.listen((location) => {
  // Do actions when navigating to another page
  if (location.pathname !== currentUri) {
    api.cancel()
  }

  previousUri = currentUri
  currentUri = location.pathname
})

export default history
