import { FC, Fragment } from 'react';
import React from 'react';

import { RoutesPath } from '@/components/core/common/helpers';
import { useGetDashboardsLayout } from '@/hooks/queries/useGetDashboardsLayout';
import { useCurrentMenu } from '@/hooks/useCurrentMenu';
import { DashboardLayoutType } from '@/models/types';
import { _translate as t } from '@/services/core';
import Dashboard from '@/views/dashboard/dashboard';

const Graphs: FC = () => {
  const { currentContent } = useCurrentMenu();
  const { data: dashboardsLayout } = useGetDashboardsLayout(currentContent?.filterTags ?? []);

  const isMapView = window.location.pathname === `/${RoutesPath.Maps}`;

  return (
    <Fragment>
      <Dashboard<DashboardLayoutType>
        data={dashboardsLayout}
        subtitle={!isMapView ? t('manager_app_see_graph') : t('manager_app_see_map')}
        title={!isMapView ? t('manager_app_graphs') : t('manager_app_maps')}
      />
    </Fragment>
  );
};

export default Graphs;
