import { Popper, Box, TextField, Button } from '@mui/material';

interface EditQRCodePopperProps {
  anchorEl: HTMLElement | null;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
  qrCodeContent: string;
  setQrCodeContent: (qrCodeContent: string) => void;
  qrCodeColor: string;
  setQrCodeColor: (qrCodeColor: string) => void;
  addQRCode: () => void;
}

const EditQRCodePopper = ({
  anchorEl,
  setAnchorEl,
  qrCodeContent,
  setQrCodeContent,
  qrCodeColor,
  setQrCodeColor,
  addQRCode,
}: EditQRCodePopperProps) => {
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const saveQRCode = () => {
    addQRCode();
    setAnchorEl(null);
  };

  return (
    <Popper id={id} open={open} anchorEl={anchorEl}>
      <Box
        sx={{
          border: 1,
          p: 1,
          bgcolor: 'background.paper',
          gap: '15px',
          display: 'flex',
          flexDirection: 'column',
          width: '200px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <TextField
            id='standard-multiline-flexible'
            multiline
            value={qrCodeContent}
            onChange={(e) => setQrCodeContent(e.target.value)}
            maxRows={4}
            variant='standard'
            placeholder='Entrez le texte'
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            justifyContent: 'space-between',
          }}
        >
          <p>Couleur:</p>
          <input
            type='color'
            value={qrCodeColor}
            onChange={(e) => setQrCodeColor(e.target.value)}
          />
        </div>
        <Button
          variant='outlined'
          style={{ color: 'black', border: '1px solid black', borderRadius: '30px' }}
          onClick={saveQRCode}
        >
          Enregistrer
        </Button>
      </Box>
    </Popper>
  );
};

export default EditQRCodePopper;
