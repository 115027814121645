import { Navigate } from '@tanstack/react-router';
import { FC, ReactNode } from 'react';

import { SearchParams } from '@/models/types';
import useCommunityStore from '@/store/community/community.store';

interface RedirectRouteProps {
  children: ReactNode;
}

const RedirectHomepage: FC<RedirectRouteProps> = ({ children }) => {
  const { communityData } = useCommunityStore();
  const firstMenu = communityData?.firstMenu;

  return communityData?.menu[0].homepage ? (
    children
  ) : (
    <Navigate
      to={firstMenu?.url ?? ''}
      search={(oldParams: SearchParams) => ({
        ...oldParams,
        menu: firstMenu?.id,
        content: firstMenu?.newContent?.[0].id,
      })}
      replace={true}
    />
  );
};

export default RedirectHomepage;
