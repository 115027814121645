/** @jsxImportSource @emotion/react */

import Tabs from '@mui/material/Tabs';
import { SyntheticEvent } from 'react';

import useCommunityStore from '@/store/community/community.store';

import { tabsStyle } from './tabs.style';

interface NavTabsProps {
  value: string | number | undefined;
  setValue?: React.Dispatch<React.SetStateAction<number | undefined>>;
  children: React.ReactNode;
  onChange?: ((event: SyntheticEvent<Element, Event>, value: any) => void) | undefined;
}

const NavTabs = ({ value, children, onChange }: NavTabsProps) => {
  const { communityData } = useCommunityStore();
  const dataPrivate = communityData?.dataPrivate;

  return (
    <div css={tabsStyle(dataPrivate)}>
      <Tabs
        value={value}
        onChange={onChange}
        aria-label='basic tabs example'
        centered
        variant='fullWidth'
        className='custom-tabs'
      >
        {children}
      </Tabs>
    </div>
  );
};

export default NavTabs;
