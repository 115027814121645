/** @jsxImportSource @emotion/react */

import { Breadcrumbs as CustomBreadcrumbs } from '@mui/material';
import { Link } from '@tanstack/react-router';
import { useContext } from 'react';

import {
  breadcrumbsItemStyle,
  breadcrumbsStyle,
} from '@/components/core/common/breadcrumbs/breadcrumbs.style';
import { Breadcrumb } from '@/models/types';
import { _translate as t, WeverCoreContext as Context } from '@/services/core';
import useCommunityStore from '@/store/community/community.store';

interface BreadcrumbsProps {
  items?: Breadcrumb[];
}

const Breadcrumbs = ({ items }: BreadcrumbsProps) => {
  const { communityData } = useCommunityStore();
  const { locale }: { locale: string } = useContext(Context);

  const isLastElement = (index: number) =>
    items && items?.length > 1 && index === items?.length - 1;

  return (
    <div css={breadcrumbsStyle(communityData?.dataPrivate)}>
      <div>
        <CustomBreadcrumbs separator='>' aria-label='breadcrumb' className='custom-breadcrumb'>
          {items?.map((breadcrumb, index) => {
            const name =
              typeof breadcrumb.name === 'string' ? t(breadcrumb.name) : breadcrumb.name?.[locale];

            return (
              <Link
                key={index}
                disabled={isLastElement(index) || !breadcrumb.url}
                to={
                  breadcrumb.url && `/${breadcrumb.url}` !== window.location.pathname
                    ? breadcrumb.url
                    : undefined
                }
                search={true}
                css={breadcrumbsItemStyle(isLastElement(index), communityData?.dataPrivate)}
              >
                {name}
              </Link>
            );
          })}
        </CustomBreadcrumbs>
      </div>
    </div>
  );
};

export default Breadcrumbs;
