/** @jsxImportSource @emotion/react */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormControl, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useState } from 'react';

import { useUpdateActionSheetStatus } from '@/hooks/mutations/useUpdateActionSheetStatus';
import { ActionSheetState } from '@/models/entities/common-types';
import { _translate as t } from '@/services/core';
import useCommunityStore from '@/store/community/community.store';
import { statusSelectStyle } from '@/views/action-sheets/components/status-select/status-select.style';

interface StatusSelectProps {
  actionSheetId: number;
  actionSheetState: ActionSheetState | null;
  stateMode: string;
}

const StatusSelect = ({ actionSheetId, actionSheetState, stateMode }: StatusSelectProps) => {
  const [value, setValue] = useState<string>(actionSheetState ?? '');
  const { communityData } = useCommunityStore();
  const { mutateAsync: updateActionSheetStatus, isPending } = useUpdateActionSheetStatus();

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
    updateActionSheetStatus({
      state: event.target.value as ActionSheetState,
      actionSheetId,
      stateMode,
    });
  };

  const options = [
    { value: ActionSheetState.Accepted, label: t('manager_app_action_sheet_status_accepted') },
    { value: ActionSheetState.Denied, label: t('manager_app_action_sheet_status_denied') },
    { value: ActionSheetState.Waiting, label: t('manager_app_action_sheet_status_waiting') },
  ];

  const statusColors = {
    [ActionSheetState.Accepted]: '#6db43b',
    [ActionSheetState.Denied]: '#d73754',
    [ActionSheetState.Waiting]: '#e0d92e',
  };

  return (
    <FormControl
      fullWidth
      disabled={isPending}
      css={statusSelectStyle(actionSheetState, communityData?.dataPrivate, statusColors[value])}
    >
      <Select
        className='status-select'
        labelId='app-simple-select-label'
        id='simple-select'
        value={value}
        fullWidth
        onChange={handleChange}
        displayEmpty
        renderValue={
          value !== ''
            ? undefined
            : () => <div style={{ color: '#aaa' }}>{t('manager_app_select_status')}</div>
        }
        defaultValue={actionSheetState ?? ''}
        IconComponent={(props) => (
          <ExpandMoreIcon
            className='pop-icon'
            style={{ color: !actionSheetState ? 'black' : 'white' }}
            {...props}
          />
        )}
      >
        {options.map((option) => (
          <MenuItem value={option.value}>
            <Stack
              borderRadius='20px'
              bgcolor={statusColors[option.value]}
              width='100%'
              textAlign='center'
              p='5px'
            >
              <Stack component={Typography} color='white'>
                {t(option.label)}
              </Stack>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StatusSelect;
