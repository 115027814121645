import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Toaster } from 'react-hot-toast';

import '@/assets/scss/main.scss';
import { router } from '@/routes/route.config';
import { _WeverCore as WeverCore } from '@/services/core';

const App: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 3600000,
        gcTime: 86400000,
        retry: false,
        retryOnMount: false,
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <WeverCore>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <Toaster />
      </QueryClientProvider>
    </WeverCore>
  );
};
export default App;
