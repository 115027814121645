import { Fragment } from 'react';
import React from 'react';

import { useGetActionSheets } from '@/hooks/queries/useGetActionSheets';
import { useCurrentMenu } from '@/hooks/useCurrentMenu';
import { ActionSheetsType } from '@/models/types';
import { _translate as t } from '@/services/core';
import Dashboard from '@/views/dashboard/dashboard';

const ActionSheets = () => {
  const { currentContent } = useCurrentMenu();
  const { data: actionSheetsData } = useGetActionSheets(currentContent?.filterTags ?? []);

  return (
    <Fragment>
      <Dashboard<ActionSheetsType>
        data={actionSheetsData}
        subtitle={t('manager_app_see_action_sheet')}
        title={t('manager_app_action_sheets')}
      />
    </Fragment>
  );
};

export default ActionSheets;
