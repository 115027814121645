import { css } from '@emotion/react';

export const structuresStyle = css`
  .structure-content {
    padding-top: 50px;

    .structure-list-content {
      .structure-list-table-container {
        padding-top: 10px;
        .structure-list-table {
          width: 100%;
          th {
            text-align: left;
            padding: 8px;

            span {
              font-size: 18px;
              font-family: 'Helvetica', sans-serif;
              font-weight: bold;
              color: #183d4c;
            }
          }
          .table-line {
            border: 2px solid #678ac7;
            border-left: none;
            td {
              text-align: left;
              padding: 8px;

              span {
                font-size: 18px;
                font-family: 'Helvetica', sans-serif;
                font-weight: 200;
                color: #183d4c;
              }

              .structure-list-uptate-button {
                cursor: pointer;
                margin-left: 10px;
                background-color: #d73755;
                border-radius: 25px 25px 25px 25px;
                text-align: center;

                span {
                  font-family: 'Helvetica', sans-serif;
                  font-size: 18px;
                  font-weight: 400;
                  color: white;
                }
              }
              .accepted {
                background-color: #c4d637;
                cursor: none;
              }
            }
          }
        }
      }
    }
    .map-select {
      padding-top: 10px;
      padding-left: 15px;
    }
    .map {
      padding-top: 30px;
    }
  }
  .structure-error {
    text-align: center;
    position: relative;
    top: 20px;
  }
`;
