import React, { FC } from 'react';

import useCommunityStore from '@/store/community/community.store';
import CommunityHomepage from '@/views/homepage/components/community-homepage/community-homepage';
import DynamicHomepage from '@/views/homepage/components/dynamic-homepage/dynamic-homepage';

const HomePage: FC = () => {
  const { communityData } = useCommunityStore();
  return communityData?.menu[0].isDynamicHomePage ? <DynamicHomepage /> : <CommunityHomepage />;
};

export default HomePage;
