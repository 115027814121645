import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { CircularProgress, IconButton } from '@mui/material';
import React from 'react';

import { Media } from '@/models/types';
import useRessources from '@/views/ressources/use-ressources';

interface DownloadProps {
  media: Media;
}

const DeleteMedia = ({ media }: DownloadProps) => {
  const { deleteRessource, uploadLoading } = useRessources();

  return (
    <IconButton onClick={() => deleteRessource(media)}>
      {uploadLoading ? <CircularProgress size={25} /> : <DeleteOutlineIcon />}
    </IconButton>
  );
};

export default DeleteMedia;
