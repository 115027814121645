import { create } from 'zustand';

import { User } from '@/models/types';
import { _Security, _Storage } from '@/services/core';

interface AuthStore {
  user: User | null;
  setUser: (user: User | null) => void;
  isAuthenticated: () => boolean;
  isGranted: (roles: string[]) => boolean;
  hasPermission: (permissions: string[]) => boolean;
  logout: () => void;
}

const useAuthStore = create<AuthStore>((set, get) => ({
  user: _Storage.get('user') ?? null,

  setUser: (user) => set({ user }),

  isAuthenticated: () => !!get().user,

  isGranted: (roles) => {
    const userRoles = get().user?.roles;
    return roles.some((role) => userRoles?.includes(role));
  },

  hasPermission: (permissions) => {
    const userPermissions = get().user?.permissions;
    return permissions.some((permission) => userPermissions?.includes(permission));
  },

  logout: () => {
    _Security.deAuthenticate();
    set({ user: null });
  },
}));

export default useAuthStore;
