/** @jsxImportSource @emotion/react */

import { faLock } from '@fortawesome/free-solid-svg-icons';
import PersonIcon from '@mui/icons-material/Person';
import { FormGroup, InputAdornment, TextField } from '@mui/material';
import { AxiosResponse } from 'axios';
import { Formik } from 'formik';
import { Fragment, useEffect, useState, useCallback } from 'react';
import { FormFeedback, Spinner } from 'reactstrap';
import * as yup from 'yup';

import {
  _Icon as Icon,
  _Notifications as notifications,
  _Security as security,
  _translate as t,
  _Api as api,
  _Storage,
} from '@/services/core';
import useAuthStore from '@/store/auth/auth.store';

import { loginFormStyle } from './login-form.style';

interface LoginFormProps {
  onSuccess: (arg: { refresh_token: string; token: string; success?: true }) => void | boolean;
  onFailed?: (arg: unknown) => void;
  setMagicLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

// IL FAUT DEPLACEMENT DANS VIEW/LOGIN
const LoginForm = ({ onSuccess, onFailed, setMagicLogin }: LoginFormProps) => {
  const [loading, setLoading] = useState(false);
  const { setUser } = useAuthStore();

  const onSubmit = async ({ username, password }: { username: string; password: string }) => {
    setLoading(true);

    try {
      const { data }: AxiosResponse<{ refresh_token: string; token: string; success: true }> =
        await api.post('/login_check', { username, password }, null, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

      security.authenticate(data.token, data.refresh_token);

      setUser(_Storage.get('user'));

      if (typeof onSuccess === 'function') {
        if (false === onSuccess(data)) {
          setLoading(false);
        }
      }
    } catch (error) {
      notifications.error(t('oops'), t('invalid_credentials'));
      if (typeof onFailed === 'function') {
        onFailed(error);
      }

      setLoading(false);
    }
  };

  const connectWithToken = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (!token) {
      return;
    }
    setLoading(true);
    const {
      data,
    }: AxiosResponse<{
      refresh_token: string;
      token: string;
      success: true;
    }> = await api.post('/users/magic_token', { magicToken: token });

    setLoading(false);
    if (!data) {
      return;
    }

    security.authenticate(data.token, data.refresh_token);

    if (typeof onSuccess === 'function') {
      onSuccess(data);
    }
  }, [onSuccess]);

  useEffect(() => {
    connectWithToken();
  }, [connectWithToken]);

  return (
    <Fragment>
      <Formik
        onSubmit={onSubmit}
        initialValues={{ username: '', password: '' }}
        validationSchema={yup.object({
          username: yup.string().required(t('field_required')),
          password: yup.string().required(t('field_required')),
        })}
      >
        {({ handleSubmit, handleBlur, errors, touched, values, handleChange }) => (
          <form noValidate onSubmit={handleSubmit} css={loginFormStyle}>
            <div className='input-title'>
              {t('manager_app_mail_address')}
              <span>&#42;</span>
            </div>
            <FormGroup className='mb-3'>
              <TextField
                fullWidth
                id='username'
                name='username'
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.username) && touched.username}
                type='email'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <PersonIcon className='person-icon' />
                    </InputAdornment>
                  ),
                }}
              />
              {touched.username && <FormFeedback>{errors.username}</FormFeedback>}
            </FormGroup>
            <div className='input-title'>
              {t('password')}
              <span>&#42;</span>
            </div>
            <FormGroup className='mb-1'>
              <TextField
                fullWidth
                id='password'
                name='password'
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.password) && touched.password}
                type='password'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Icon name={faLock} className='lock-icon' />
                    </InputAdornment>
                  ),
                }}
              />

              {touched.password && <FormFeedback>{errors.password}</FormFeedback>}
            </FormGroup>
            <div className='text-center'>
              {loading ? (
                <Spinner className='form-spinner' />
              ) : (
                <button className='submit-button w-100' type='submit'>
                  {t('connexion')}
                </button>
              )}
            </div>
            {/* <div className='bottom-title'>
              <span className='forgot-password'>{t('managerapp_forgot_password')}</span>
              <span onClick={() => setMagicLogin(true)} className='forgot-password-link'>
                {t('managerapp_use_magic_link')}
              </span>
            </div> */}
          </form>
        )}
      </Formik>
    </Fragment>
  );
};

export default LoginForm;
