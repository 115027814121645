/** @jsxImportSource @emotion/react */

import { Button } from '@mui/material';

import { CustomTable, Column } from '@/components/core/common/table/custom-table';
import {
  StructureReferencement,
  StructureValidationState,
  StructureValidationStateEnum,
} from '@/models/types';
import { _translate as t } from '@/services/core';
import { structuresTableStyle } from '@/views/structure-list/components/structure-table.style';

export interface RowData {
  [key: string]: string | number | React.ReactNode | undefined;
  name: string;
  sites?: string;
  capacity?: number;
  button?: React.ReactNode;
}

interface StructureTableProps {
  data: StructureReferencement[] | undefined;
  status: StructureValidationState;
  updateStatus: (structureId: number) => Promise<void>;
}

const StructureTable = ({ data, status, updateStatus }: StructureTableProps) => {
  const columns: Column[] = [
    {
      id: 'name',
      label: t('manager_app_structure_name'),
      minWidth: 170,
      display: true,
    },
    {
      id: 'sites',
      label: t('manager_app_structure_address'),
      minWidth: 170,
      align: 'center',
      display: true,
    },
    {
      id: 'capacity',
      label: t('manager_app_structure_team'),
      minWidth: 170,
      align: 'center',
      display: true,
    },
    {
      id: 'button',
      label: '',
      minWidth: 170,
      align: 'center',
      display: true,
    },
  ];

  const dataByStatus = data?.filter((structure) => structure.state === status);

  const rows = dataByStatus?.map(({ name, sites, id, state }) => ({
    name: name ?? '',
    sites: sites[0]?.address?.address,
    capacity: sites[0]?.capacity ?? undefined,
    button: (
      <Button
        className={`structure-list-uptate-button ${
          state === StructureValidationStateEnum.accepted && 'accepted'
        } `}
        onClick={() => state !== StructureValidationStateEnum.accepted && updateStatus(id)}
        variant='text'
      >
        {state !== StructureValidationStateEnum.accepted
          ? t('manager_app_save_structure')
          : t('manager_app_structure_accepted')}
      </Button>
    ),
  }));

  return (
    <div css={structuresTableStyle}>
      <CustomTable<RowData> columns={columns} rows={rows ?? []} />
    </div>
  );
};

export default StructureTable;
