import { useSearch } from '@tanstack/react-router';

import { SearchParams } from '@/models/types';
import useCommunityStore from '@/store/community/community.store';
import { findByInNestedArray } from '@/utils/utils';

export const useCurrentMenu = () => {
  const search = useSearch({
    strict: false,
  }) satisfies SearchParams;

  const { communityData } = useCommunityStore();
  const currentMenu = findByInNestedArray(communityData?.menu ?? [], 'id', search.menu, 'menu');
  const currentContent = currentMenu?.newContent?.find((content) => content.id === search.content);

  return { currentMenu, currentContent, search };
};
