import { Switch } from '@mui/material';
import React, { useState } from 'react';
import { Button, Input, Col, Row, Label, FormGroup } from 'reactstrap';

import CopilMemberInterface from '@/models/entities/copil-member-interface';
import { _translate as t } from '@/services/core';

type FieldProps = {
  col: string;
  type?: string;
  name: string;
  onChange: (name: string, value: boolean | string) => void;
  value?: string | boolean;
  disabled?: boolean;
};

type CardUserProps = {
  onValidate: (form: CopilMemberInterface) => void;
  initialState?: CopilMemberInterface;
  submitLabel?: string;
};

const Field = ({ col, type = 'text', name, onChange, value, disabled }: FieldProps) => {
  const Tag = type === 'switch' ? Switch : Input;
  const fieldValue: string = type === 'switch' ? 'checked' : 'value';

  return (
    <Col md={col || 4} sm='12'>
      <FormGroup className='w-100'>
        <Label className='w-100'>
          <>
            <p className='mb-3 fw-bold'>{t(`manager_app_form_${name}`)}</p>
            {disabled ? (
              value
            ) : (
              <Tag
                onChange={(e) => onChange(name, type === 'switch' ? !value : e.target.value)}
                {...{ [fieldValue]: value }}
              />
            )}
          </>
        </Label>
      </FormGroup>
    </Col>
  );
};

const outerInitialState: CopilMemberInterface = {
  fullName: undefined,
  firstname: undefined,
  lastname: undefined,
  job: undefined,
  phone: undefined,
  email: undefined,
  referent: false,
};

const CardUser = ({ onValidate, initialState, submitLabel }: CardUserProps) => {
  const [form, setForm] = useState<CopilMemberInterface>(initialState || outerInitialState);
  const isEmailExist: boolean = initialState?.email !== null && initialState?.email !== undefined;
  const col = submitLabel ? '12' : '4';

  const handleSubmit = () => {
    onValidate(form);
    setForm(outerInitialState);
  };

  const handleChange = (name: string, value: string | boolean) =>
    setForm({ ...form, [name]: value });

  if (submitLabel && !initialState) {
    return null;
  }

  return (
    <Row>
      <Field col={col} value={form?.lastname || ''} name='lastname' onChange={handleChange} />
      <Field col={col} value={form?.firstname || ''} name='firstname' onChange={handleChange} />
      <Field
        col={col}
        value={form?.referent}
        name='referent'
        type='switch'
        onChange={handleChange}
      />
      <Field
        col={col}
        value={form?.email || ''}
        disabled={isEmailExist}
        name='email'
        onChange={handleChange}
      />
      <Field col={col} value={form?.job || ''} name='job' onChange={handleChange} />
      <Col col={submitLabel ? '12' : '2'} className='d-flex align-items-center'>
        <Button className='bg-dark-pink text-uppercase' onClick={handleSubmit}>
          {' '}
          {submitLabel || t('manager_app_form_validate')}
        </Button>
      </Col>
    </Row>
  );
};

export default CardUser;
