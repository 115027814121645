import { css } from '@emotion/react';

export const loginFormStyle = css`
  .MuiInputBase-root {
    border-radius: 40px;
    background-color: #f2f2f2;
    height: 50px;

    .MuiInputAdornment-root {
      width: 10px;
    }

    .person-icon {
      color: #00aad7;
    }

    .lock-icon {
      color: #00aad7;
    }

    .MuiInputBase-input {
      margin: 15px;
      padding: 5px;
      border-radius: 0;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }

  .text-center {
    .form-spinner {
      color: #00aad7;
    }
  }

  .bottom-title {
    padding-top: 10px;
    text-align: center;
    cursor: pointer;

    .forgot-password-link {
      padding-left: 10px;
      cursor: pointer;
      color: #d73755;
    }
  }
`;
