import Collection from 'wever-core/dist/components/common/collection'
import Date from 'wever-core/dist/components/common/date'
import ExternalLink from 'wever-core/dist/components/common/external-link'
import Icon from 'wever-core/dist/components/common/icon'
import Modal from 'wever-core/dist/components/common/modal'
import Pagination from 'wever-core/dist/components/common/pagination'

export const _Icon = Icon
export const _Date = Date
export const _Collection = Collection
export const _Pagination = Pagination
export const _ExternalLink = ExternalLink
export const _Modal = Modal

const DefaultObject = {
  _Icon,
  _Date,
  _Collection,
  _Pagination,
  _ExternalLink,
  _Modal,
}

export default DefaultObject
