import { ActionsSheetInterface } from '@/models/entities/actions-sheets-interface';
import { _translate as t } from '@/services/core';

export const actionsSheetsTabs = (actionSheet?: ActionsSheetInterface) => [
  { title: t('manager_app_description_dashboard'), value: true },
  {
    title: t('manager_app_evaluation_dashboard'),
    value: Boolean(actionSheet?.evaluationDashboard),
  },
  { title: t('manager_app_dimension_dashboard'), value: Boolean(actionSheet?.dimensionDashboard) },
  { title: t('manager_app_feedback_dashboard'), value: Boolean(actionSheet?.feedbackDashboard) },
];
