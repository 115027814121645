import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import SpeedIcon from '@mui/icons-material/Speed';
import BalanceIcon from '@mui/icons-material/Balance';



import { Button, Tooltip } from '@mui/material';
import { ResponsiveBar, BarDatum } from '@nivo/bar';
import { useParams } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import Select, { SingleValue } from 'react-select';

import { _Api, _Notifications, _translate as t } from '@/services/core';
import useCommunityStore from '@/store/community/community.store';

interface AnalyticsEvent {
  type: string;
  triggered: number;
}

interface AnalyticsQuestion {
  alias: string;
  completionMin: number;
  completionMax: number;
  completionAverage: number;
  nbUser: number;
  nbUserLeaving: number;
  nbUserRollingBack: number;
  questionId: number;
  nbUseLanding: number;
  nbUserLanding: number;
  previousQuestionId: number;
}

interface AnalyticsHistoric {
  createdAt: number;
  completionMin: number;
  completionMax: number;
  userEnded: number;
  userLanding: number;
  userLeaving: number;
  userRollback: number;
  events: AnalyticsEvent[];
  questions: AnalyticsQuestion[];
}

interface Analytics {
  nbUserLanding: number;
  totalLeaving: number;
  diag: number;
  historics: AnalyticsHistoric[];
  questions: AnalyticsQuestion[];
  nbTotalLeaving: number;
  nbUserLeavingAfterFirstQuestion: number;
  nbTotalLandingOnFirstQuestion: number;
  nbSaveTotal: number;
  nbTotalSaveOnCGV: number;
  charts: BarDatum[];
}

const transformDate = (timestamp: number): string => {
  const date = new Date(timestamp);
  const day = date.getDate();
  const month = date.getMonth() + 1;

  return `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${date.getFullYear()}`;
};

const DashboardAnalytics = () => {
  const { communityData } = useCommunityStore();
  const { id }: { id: string } = useParams({ strict: false });
  const [item, setItem] = useState<Analytics | null>(null);
  const [charts, setCharts] = useState<BarDatum[]>([]);
  const [questions, setQuestions] = useState<AnalyticsQuestion[]>([]);
  // const [filterDate, setFilterDate] = useState<string | null>(null);
  // const [filterSort, setFilterSort] = useState<string | undefined>(undefined);
  const [maxEntrie, setMaxEntrie] = useState(0);
  const [average, setAverage] = useState(0);
  const [csvData, setCsvData] = useState<any[]>([]);

  const loadListing = async () => {
    if (!id) {
      return;
    }

    try {
      const response = await _Api.get(`/analytics/diagnostics/${id}/items`);
      const analytics = response.data as Analytics;

      let averageSum = 0;
      analytics.historics.forEach(historic => {
        averageSum += (historic.completionMax + historic.completionMin) / 2;
      })

      if (analytics.historics.length > 0) {
        setAverage(averageSum / analytics.historics.length);
      }

      setQuestions(analytics.questions);
      setItem(analytics);
      setCharts(analytics.charts);
      setMaxEntrie(analytics.nbTotalLandingOnFirstQuestion);
    } catch (e) {
      _Notifications.error(t('manager_app_api_error'), t('manager_app_api_access_denied'));
    }
  };

  const getChartsDateAsOption = (
    chartsOpt: { date: string }[],
  ): { value: string; label: string }[] => {
    return chartsOpt.map((chart) => {
      const fChart = chart as unknown as { date: string };
      return { value: fChart.date, label: fChart.date };
    });
  };

  const handleQuestionListChange = (
    option: SingleValue<{ value: string; label: any }>,
  ): AnalyticsQuestion[] => {
    if (!item) {
      return questions;
    }

    let goodQuestions: AnalyticsQuestion[] = item.questions;
    if (option?.value === 'all' || !option?.value) {
      setQuestions(item.questions);

      return goodQuestions;
    }

    //setFilterDate(option?.value);

    item.historics.forEach((historic) => {
      const date = transformDate(historic.createdAt * 1000);

      if (date === option?.value) {
        setQuestions(historic.questions);
        goodQuestions = historic.questions;
      }
    });

    return goodQuestions;
  };

  const handleSorting = (option: SingleValue<{ value: string; label: any }>) => {
    if (!item) {
      return;
    }

    if (!option || !option.value || option.value === 'none') {
      setQuestions(item.questions);
    }

    //setFilterSort(option?.value);

    let questionsSorted: AnalyticsQuestion[] = [];
    switch (option?.value) {
      case 'inputs_asc':
        questionsSorted = questions.sort((a, b): number => {
          if (a.nbUseLanding) {
            return a.nbUseLanding > b.nbUseLanding ? 0 : -1;
          }

          return a.nbUserLanding > b.nbUserLanding ? 0 : -1;
        });
        break;
      case 'outputs_asc':
        questionsSorted = questions.sort((a, b) => {
          if (a.nbUseLanding) {
            const leavingA = a.nbUseLanding - (a.nbUser + a.nbUserRollingBack);
            const leavingB = b.nbUseLanding - (b.nbUser + b.nbUserRollingBack);

            return leavingA - leavingB;
          }

          const leavingA = a.nbUserLanding - (a.nbUser + a.nbUserRollingBack);
          const leavingB = b.nbUserLanding - (b.nbUser + b.nbUserRollingBack);

          return leavingA - leavingB;
        });
        break;
      case 'inputs_desc':
        questionsSorted = questions.sort((a, b) => {
          if (a.nbUseLanding) {
            return b.nbUseLanding - a.nbUseLanding;
          }

          return b.nbUserLanding - a.nbUserLanding;
        });
        break;
      case 'outputs_desc':
        questionsSorted = questions.sort((a, b) => {
          if (a.nbUseLanding) {
            const leavingA = a.nbUseLanding - (a.nbUser + a.nbUserRollingBack);
            const leavingB = b.nbUseLanding - (b.nbUser + b.nbUserRollingBack);

            return leavingB - leavingA;
          }

          const leavingA = a.nbUserLanding - (a.nbUser + a.nbUserRollingBack);
          const leavingB = b.nbUserLanding - (b.nbUser + b.nbUserRollingBack);

          return leavingB - leavingA;
        });
        break;
    }

    setQuestions([...questionsSorted]);
  };

  const handlingMaxEntry = (option: SingleValue<{ value: string; label: any }>) => {
    if (!item) return;

    if (!option || !option?.value) {
      setMaxEntrie(item.nbTotalLandingOnFirstQuestion);
    }

    switch (option?.value) {
      case 'all':
        setMaxEntrie(item.nbUserLanding);
        break;
      case 'cgv':
        setMaxEntrie(item.nbTotalLandingOnFirstQuestion);
        break;
      case 'after_cgv':
        setMaxEntrie(item.nbTotalSaveOnCGV);
        break;
    }
  };

  const createCsv = () => {
    const els = questions.map((question) => {
      const landing = question.nbUseLanding ?? question.nbUserLanding;
      const leaving = landing - (question.nbUser + question.nbUserRollingBack);

      return [
        question.alias,
        question.nbUseLanding ?? question.nbUserLanding,
        leaving,
        question.nbUserRollingBack,
        question.nbUser,
        question.completionMin,
        question.completionMax,
      ];
    });

    setCsvData([['Alias', 'Entrées', 'Sorties', 'Retours', 'Suivant', 'Temps Min', 'Temps Max'], ...els]);
  };

  const convertSecondToMin = (seconds: number): string => {
    if (seconds < 60) {
      return `${(seconds).toFixed(0)} s`
    }

    return `${(seconds / 60).toFixed(0)} min`;
  }

  useEffect(() => {
    loadListing();
    // Disable the eslint rule for exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!communityData || !item || !charts) {
    return null;
  }

  return (
    <>
      <div
        className='analytics-title'
        style={{ color: communityData?.dataPrivate?.secondaryColor?.value ?? '#d73755' }}
      >
        Diagnostic : {item.diag}
      </div>
      <div className='row row-stats'>
        <div className='col col-3'>
          <div className='col-stats-container'>
            <div>
              {item.nbTotalLandingOnFirstQuestion}{' '}
              <span className='input'>
                <ArrowUpwardIcon />
              </span>
            </div>
            <p>
              <Tooltip
                title={`Nombre d'utilisateur arrivant sur le site, dont ${item.nbTotalSaveOnCGV} qui ont commencé le diagnostic`}
              >
                <div>
                  Entrées total <InfoIcon className='info-icon' />
                </div>
              </Tooltip>
            </p>
          </div>
        </div>
        <div className='col col-3'>
          <div className='col-stats-container'>
            <div>
              {convertSecondToMin(average)}{' '}
              <span className=''>
                <SpeedIcon />
              </span>
            </div>
            <p>
              <Tooltip title="Temps moyen de complétion du diagnostic">
                <div>
                  {' '}
                  Temps moyen <InfoIcon className='info-icon' />
                </div>
              </Tooltip>
            </p>
          </div>
        </div>

        <div className='col col-3'>
          <div className='col-stats-container'>
            <div>
              {item.nbUserLeavingAfterFirstQuestion}{' '}
              <span className='output'>
                <ArrowDownwardIcon />
              </span>
            </div>
            <p>
              <Tooltip title='Sortie sans compter les CGV et la question anonyme (fin de diag)'>
                <div>
                  {' '}
                  Sorties <InfoIcon className='info-icon' />
                </div>
              </Tooltip>
            </p>
          </div>
        </div>

        <div className='col col-3'>
          <div className='col-stats-container'>
            <div>
              {item.nbSaveTotal}{' '}
              <span className='input'>
                <SaveIcon />
              </span>
            </div>
            <p>Sauvegardes</p>
          </div>
        </div>
      </div>

      <div className='row row-chart my-5'>
        <div className='col col-12'>
          {charts.length > 0 && (
            <ResponsiveBar
              data={charts}
              keys={['Entrées', 'Sorties', 'Retours', 'Sauvegardés']}
              indexBy='date'
              groupMode='stacked'
              margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
              padding={0.3}
              defs={[
                {
                  id: 'dots',
                  type: 'patternDots',
                  background: 'inherit',
                  color: '#38bcb2',
                  size: 1.5,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: 'lines',
                  type: 'patternLines',
                  background: 'inherit',
                  color: '#eed312',
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
              fill={[
                {
                  match: {
                    id: 'Sauvegardés',
                  },
                  id: 'dots',
                },
                {
                  match: {
                    id: 'Sorties',
                  },
                  id: 'lines',
                },
              ]}
              axisTop={null}
              axisRight={null}
              axisLeft={null}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={{ scheme: 'nivo' }}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Date',
                legendPosition: 'middle',
                legendOffset: 30,
                truncateTickAt: 0,
              }}
              legends={[
                {
                  dataFrom: 'keys',
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          )}
        </div>
      </div>

      <div className='row row-question'>
        <div className='col col-12 my-2 col-filtering'>
          <div className='filter-item'>
            <Select
              className='timed-question'
              classNamePrefix='select'
              defaultValue={{ value: 'all', label: t('Dates: Toutes') }}
              isSearchable
              name='color'
              onChange={(option) => handleQuestionListChange(option)}
              options={[
                { value: 'all', label: t('Dates: Toutes') },
                ...getChartsDateAsOption(charts as unknown as { date: string }[]),
              ]}
            />
          </div>
          <div className='pipe' />
          <div className='filter-item'>
            <Select
              className='filter-select'
              classNamePrefix='select'
              defaultValue={{ value: 'none', label: t('Aucun tri appliqué') }}
              placeholder='Aucun tri appliqué'
              isClearable
              isSearchable
              name='color'
              onChange={(option) => handleSorting(option)}
              options={[
                { value: 'none', label: t('Aucun tri appliqué') },
                { value: 'inputs_asc', label: t('Entrées - Croissant') },
                { value: 'outputs_asc', label: t('Sorties - Croissant') },
                { value: 'inputs_desc', label: t('Entrées - Décroissant') },
                { value: 'outputs_desc', label: t('Sorties - Décroissant') },
              ]}
            />
          </div>
          <div className='pipe' />
            <div className='filter-item'>
              <Select
                className='filter-select'
                classNamePrefix='select'
                defaultValue={{ value: 'cgv', label: t('Entrée: Nb. pers. sur les CGV ') }}
                placeholder='Entrée principale'
                isClearable
                isSearchable
                name='color'
                onChange={(option) => handlingMaxEntry(option)}
                options={[
                  { value: 'all', label: t('Nb pers. allant sur le diagnostic)') },
                  { value: 'cgv', label: t('Nb. pers. sur les CGV') },
                  { value: 'after_cgv', label: t('Nb pers. commançant le diagnostic') },
                ]}
              />
            </div>
            <Tooltip title="Permet de changer la valeur d'entrée, celle des personnes utilisant le lien du diagnostic, ceux qui arrivent sur les CGV, ou encore ceux qui ont commencé le diagnostic.">
              <InfoIcon className='info-icon' />
            </Tooltip>
          <div className='pipe' />
          <div className='filter-item'>
            {csvData.length === 0 ? (
              <Button className='btn-export text-black' onClick={createCsv}>
                Exporter en CSV
              </Button>
            ) : (
              <CSVLink data={csvData} onClick={() => setCsvData([])} filename={'export-analytics.csv'} target='_blank'>
                Télécharger
              </CSVLink>
            )}
          </div>
        </div>
        {questions.map((question, index) => {
          if (question.alias.indexOf('anonyme') >= 0) {
            return null;
          }

          return (
            <div className='col col-12 col-question' key={index + '_' + question.alias}>
              <div className='alias'>{question.alias}</div>
              <div className="col-question-row">
                <div className="row-1">
              <Tooltip title='Nb personne arrivant sur la question'>
                <div className='number'>
                  <span className='input'>
                    <ArrowUpwardIcon />
                  </span>
                  <span>
                    {question.nbUserLanding || question.nbUseLanding} (
                    {(
                      ((question.nbUserLanding || question.nbUseLanding) * 100) /
                      maxEntrie
                    ).toFixed(1)}
                    %)
                  </span>
                </div>
              </Tooltip>

              <Tooltip title='Nb personne sortant à cette question'>
                <div className='number'>
                  <span className='output'>
                    <ArrowDownwardIcon />
                  </span>
                  <span>
                    {(question.nbUserLanding ?? question.nbUseLanding) -
                      (question.nbUser + question.nbUserRollingBack)}{' '}
                    (
                    {(
                      (((question.nbUserLanding ?? question.nbUseLanding) -
                        (question.nbUser + question.nbUserRollingBack)) *
                        100) /
                      maxEntrie
                    ).toFixed(1)}
                    %){' '}
                  </span>
                </div>
              </Tooltip>

              <Tooltip title='Nb personne retournant en arrière'>
                <div className='number'>
                  <span className='rollback'>
                    <RefreshIcon />
                  </span>
                  <span>
                    {question.nbUserRollingBack} (
                    {((question.nbUserRollingBack * 100) / maxEntrie).toFixed(1)}%)
                  </span>
                </div>
              </Tooltip>
              <Tooltip title='Nb personne allant à la question suivante'>
                <div className='number'>
                  <span className='forward'>
                    <CheckIcon />
                  </span>
                  <span>
                    {question.nbUser} ({((question.nbUser * 100) / maxEntrie).toFixed(1)}%)
                  </span>
                </div>
              </Tooltip>
              </div>
              <div className="row-1 mt-2">
                  <div className='number'>
                    <BalanceIcon className="small-icon" /> {convertSecondToMin((question.completionMax + question.completionMin) / 2)}
                    <Tooltip title="Temps moyen">
                      <InfoIcon className='info-icon' />
                    </Tooltip>
                  </div>

                  <div className='number'>
                    <SpeedIcon className="small-icon"/> {convertSecondToMin(question.completionMin)}
                    <Tooltip title="Temps Min.">
                      <InfoIcon className='info-icon' />
                    </Tooltip>
                  </div>

                  <div className='number'>
                    <AccessAlarmIcon className="small-icon" /> {convertSecondToMin(question.completionMax)}
                    <Tooltip title="Temps Max.">
                      <InfoIcon className='info-icon' />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default DashboardAnalytics;
