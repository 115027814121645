import { Checkbox, Dialog, DialogContent, FormControlLabel, Grid } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';

import templatePortail1Empty from '@/assets/img/template/portails/template-portail-1-empty.png';
import templatePortail1 from '@/assets/img/template/portails/template-portail-1.png';
import templatePortail2Empty from '@/assets/img/template/portails/template-portail-2-empty.png';
import templatePortail2 from '@/assets/img/template/portails/template-portail-2.png';
import templatePortail3Empty from '@/assets/img/template/portails/template-portail-3-empty.png';
import templatePortail3 from '@/assets/img/template/portails/template-portail-3.png';
import templatePortail4Empty from '@/assets/img/template/portails/template-portail-4-empty.png';
import templatePortail4 from '@/assets/img/template/portails/template-portail-4.png';
import templatePortail5Empty from '@/assets/img/template/portails/template-portail-5-empty.png';
import templatePortail5 from '@/assets/img/template/portails/template-portail-5.png';
import templatePortail6Empty from '@/assets/img/template/portails/template-portail-6-empty.png';
import templatePortail6 from '@/assets/img/template/portails/template-portail-6.png';
import templatePortail7Empty from '@/assets/img/template/portails/template-portail-7-empty.png';
import templatePortail7 from '@/assets/img/template/portails/template-portail-7.png';
import template1 from '@/assets/img/template/template-1.png';
import template2 from '@/assets/img/template/template-2.png';
import template3 from '@/assets/img/template/template-3.png';
import template4 from '@/assets/img/template/template-4.png';
import template5 from '@/assets/img/template/template-5.png';
import template6 from '@/assets/img/template/template-6.png';
import template7 from '@/assets/img/template/template-7.png';

interface TemplateDialogProps {
  open: boolean;
  handleClose: () => void;
  setImageURL: (imageURL: string) => void;
}

const TemplateDialog = ({ open, handleClose, setImageURL }: TemplateDialogProps) => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [portailImagesLoaded, setPortailImagesLoaded] = useState(false);
  const [showPortailTemplate, setShowPortailTemplate] = useState(false);

  const templates = useMemo(
    () => [template1, template2, template3, template4, template5, template6, template7],
    [],
  );
  const templatesPortail = useMemo(
    () => [
      templatePortail1Empty,
      templatePortail1,
      templatePortail2Empty,
      templatePortail2,
      templatePortail3Empty,
      templatePortail3,
      templatePortail4Empty,
      templatePortail4,
      templatePortail5Empty,
      templatePortail5,
      templatePortail6Empty,
      templatePortail6,
      templatePortail7Empty,
      templatePortail7,
    ],
    [],
  );

  useEffect(() => {
    let loadedCount = 0;
    templates.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        loadedCount++;
        if (loadedCount === templates.length) {
          setImagesLoaded(true);
        }
      };
    });
  }, [templates]);

  useEffect(() => {
    let loadedCount = 0;
    templatesPortail.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        loadedCount++;
        if (loadedCount === templatesPortail.length) {
          setPortailImagesLoaded(true);
        }
      };
    });
  }, [templatesPortail]);

  const templatesToShow = showPortailTemplate ? templatesPortail : templates;

  if (!imagesLoaded || !portailImagesLoaded) {
    return 'Chargement des templates en cours...';
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={showPortailTemplate}
              onChange={(e) => setShowPortailTemplate(e.target.checked)}
              name='showPortailTemplate'
            />
          }
          label='Afficher les templates Portail'
        />
        <Grid container spacing={2}>
          {templatesToShow.map((template, index) => (
            <Grid item xs={6} key={index}>
              <img
                src={template}
                alt='Template 1'
                style={{ width: '100%', marginTop: '10px', cursor: 'pointer' }}
                className='hover-border'
                onClick={() => setImageURL(template)}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default TemplateDialog;
