import { useState } from 'react';

const useStructureList = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleChange = (_event: React.SyntheticEvent<Element, Event>, value: number) => {
    setActiveTab(value);
  };

  return { activeTab, handleChange };
};

export default useStructureList;
