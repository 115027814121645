import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Community } from '@/models/types';
import { getCommunity } from '@/services/api';

export const useGetCommunity = (communityId: number) => {
  return useQuery<Community, AxiosError>({
    queryKey: ['community', communityId],
    queryFn: () => getCommunity(communityId),
    enabled: Boolean(communityId),
    staleTime: 0,
    gcTime: 0,
  });
};
