/** eslint-disable react-hooks/exhaustive-deps */

import LoginIcon from '@mui/icons-material/Login';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { ListItemText, ListItem, List, ListItemAvatar, Avatar } from '@mui/material';
import { Link } from '@tanstack/react-router';
import { useEffect, useState } from 'react';

import { _Api, _Notifications, _translate as t } from '@/services/core';
import useCommunityStore from '@/store/community/community.store';

interface Analytics {
  _id: number;
  name: { [key: string]: string; fr: string };
  analytic: number;
  createdAt: {
    $date: { $numberLong: string };
  };
}

const ListDashboardAnalytics = () => {
  const { communityData } = useCommunityStore();
  const [list, setList] = useState<Analytics[]>([]);

  const loadListing = async () => {
    if (!communityData) {
      return;
    }

    try {
      const response = await _Api.get(`/analytics/diagnostics/${communityData.id}/list`);
      setList(response.data as Analytics[]);
    } catch (e) {
      _Notifications.error(t('manager_app_api_error'), t('manager_app_api_access_denied'));
    }
  };

  const transformDate = (timestamp: number): string => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;

    return `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${date.getFullYear()}`;
  };

  useEffect(() => {
    loadListing();
    // Disable the eslint rule for exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!communityData) {
    return null;
  }

  return (
    <>
      <div
        className='analytics-title'
        style={{ color: communityData?.dataPrivate?.secondaryColor?.value ?? '#d73755' }}
      >
        Liste des analyses
      </div>
      <List className='analytics-list-container'>
        {list.map((element) => (
          <Link to={'/analytics/$id'} params={{ id: element.analytic.toString() }}>
            <ListItem className='analytics-list-item' secondaryAction={<LoginIcon />}>
              <ListItemAvatar>
                <Avatar>
                  <QueryStatsIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={element.name.fr}
                secondary={transformDate(parseInt(element.createdAt.$date.$numberLong))}
              />
            </ListItem>
          </Link>
        ))}
      </List>
    </>
  );
};

export default ListDashboardAnalytics;
