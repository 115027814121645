/** @jsxImportSource @emotion/react */

import { useNavigate } from '@tanstack/react-router';
import React, { useContext } from 'react';
import 'wever-dashboard/dist/styles/bootstrap.css';

import CustomAutocomplete from '@/components/core/common/autocomplete/custom-autocomplete';
import Breadcrumbs from '@/components/core/common/breadcrumbs/breadcrumbs';
import { RoutesPath } from '@/components/core/common/helpers';
import translate from '@/components/core/common/translate';
import LoadingLayout from '@/components/layout/loading-layout';
import { useGetDashboardsLayout } from '@/hooks/queries/useGetDashboardsLayout';
import useWindowSize from '@/hooks/use-window-size';
import { useCurrentMenu } from '@/hooks/useCurrentMenu';
import { Breadcrumb, GraphInterface } from '@/models/types';
import {
  _WeverDashboard as WeverDashboard,
  _Security as security,
  _translate as t,
  WeverCoreContext as Context,
} from '@/services/core';
import useCommunityStore from '@/store/community/community.store';
import { graphStyle } from '@/views/graphs/single-graph/graph.style';
import useSingleGraph from '@/views/graphs/single-graph/use-single-graph';

const Graph = () => {
  const { communityData } = useCommunityStore();
  const { currentContent, currentMenu } = useCurrentMenu();
  const { data: dashboardsLayout, isLoading: dashboardsLayoutLoading } = useGetDashboardsLayout(
    currentContent?.filterTags ?? [],
  );
  const { locale } = useContext<Record<string, string>>(Context);
  const { isLoading, singleGraph, reportId, groupedDataByJourneyType } = useSingleGraph(
    dashboardsLayout,
    currentContent!,
    locale,
    communityData,
  );
  const { windowSize } = useWindowSize();

  const navigate = useNavigate();
  const isMapView =
    window.location.pathname === `/${RoutesPath.Maps}/${singleGraph?.id}` ? true : false;

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: GraphInterface | null,
  ) => {
    navigate({
      params: { id: newValue?.id },
      search: true,
    });
  };

  if (isLoading) {
    return <LoadingLayout />;
  }

  if (!reportId) {
    return <div className='w-100 text-center'>{t('adminapp_no_resource_for_this_community')}</div>;
  }

  const breadcrumbs: Breadcrumb[] = [
    ...(currentContent?.breadcrumbs ?? currentMenu?.breadcrumbs ?? []),
    { name: singleGraph?.name[locale] ?? '', url: '' },
  ];

  return (
    <div css={graphStyle(isMapView, windowSize.innerHeight)}>
      <div className='graph-breadcrumbs'>
        <Breadcrumbs items={breadcrumbs} />
      </div>
      <div className='graph-select'>
        <CustomAutocomplete<GraphInterface>
          handleChange={handleChange}
          data={groupedDataByJourneyType}
          defaultValue={singleGraph}
          getOptionLabel={(option) => option.name[locale] ?? ''}
          groupBy={(option) => (option.journeyType ? option.journeyType : null)}
          isLoading={dashboardsLayoutLoading}
          loadingIconSize={15}
          renderOption={(props, option) => (
            <li style={{ fontSize: '16px' }} {...props}>
              {translate(option.name)}
            </li>
          )}
          size='small'
        />
      </div>
      <div className='graph graph-dashboard-container'>
        <WeverDashboard
          editionMode
          dashboard={singleGraph?.id}
          locale={locale}
          token={security.token}
          report={reportId}
          community={communityData?.id}
        />
      </div>
    </div>
  );
};

export default Graph;
