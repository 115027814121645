import React from 'react';
import { useDropzone } from 'react-dropzone';

import uploadIcon from '@/assets/img/icons/common/upload-icon.png';
import { _translate as t } from '@/services/core';

interface UplaodProps {
  uploadRessource: (formData: FormData, filename: string) => Promise<void>;
}

const Uplaod = ({ uploadRessource }: UplaodProps) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: async (acceptedFiles) => {
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
      await uploadRessource(formData, acceptedFiles[0].name);
    },
  });

  return (
    <div className='upload-container'>
      <div {...getRootProps({ className: 'dropzone' })}>
        <div onClick={open}>
          <img alt='...' src={uploadIcon} />
        </div>
        <input {...getInputProps()} />
        <div className='content'>
          <p>{t('manager_app_select_file')}</p>
          <span>{t('manager_app_format_size')}</span>
        </div>
      </div>
    </div>
  );
};

export default Uplaod;
