import { css } from '@emotion/react';

export const sidebarMenuStyle = (open: boolean) => css`
  width: ${open ? '250px' : 'calc(78px + 1px)'};
  flex-shrink: 0;
  white-space: nowrap;
  box-sizing: border-box;
  transition: ${open
    ? 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
    : 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'};
  overflowx: hidden;
  background-color: #f2f2f2;
  border: none;

  .MuiDrawer-paperAnchorLeft {
    width: ${open ? '250px' : 'calc(78px + 1px)'};
    transition: ${open
      ? 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
      : 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'};
    overflowx: hidden;
    background-color: #f2f2f2;
    border: none;
  }

  .logo {
    display: flex;
    justify-content: center;
    padding-top: ${open ? '30px' : '10px'};

    img {
      width: 50%;
    }
  }

  .community-select {
    hr {
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 35px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .sidebar-menu {
    flex-grow: 1;
  }

  .down-logo {
    width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .toggle {
    cursor: pointer;
    display: flex;
    justify-content: ${open ? 'flex-end' : 'center'};
    padding-bottom: 20px;
    padding-right: ${open ? '10px' : '0'};
    flex-shrink: 0;
    .MuiSvgIcon-root {
      color: #183d4c;
    }
    span {
      font-size: 16px;
      font-family: 'Helvetica', 'Arial', sans-serif;
      font-weight: 400;
      color: #183d4c;
    }
  }

  @media (min-width: 600px) {
    width: 'calc(64px + 1px)';
  }
`;
