import { css } from '@emotion/react';

export const ressourcesTableStyle = css`
  overflow: hidden;
  width: 100%;

  .custom-table-container {
    .custom-table-header-cell {
      font-size: 12px;
      color: #9b9b9b;
      text-align: center;
    }

    .custom-table-body-cell-icon {
      min-height: 40px;
      height: 40px;

      .table-cell-box {
        display: flex;
        align-items: center;
      }

      svg {
        font-size: 20px;
      }

      .value {
        padding-left: 10px;
        padding-top: 3px;
      }
    }

    .custom-table-body-cell {
      min-height: 40px;
      height: 40px;
      text-align: center;
    }
  }
`;
