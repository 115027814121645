/** @jsxImportSource @emotion/react */

import { ReactNode } from 'react';

import { tabPanelStyle } from './tabs.style';

interface TabPanelProps {
  children?: ReactNode;
  index: string | number | undefined;
  value: string | number | undefined;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      css={tabPanelStyle}
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

export default TabPanel;
