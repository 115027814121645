import View from '../../../components/view'
import { AccessDenied } from '../../../views/error'
import history, { previousUri } from './history'
import {
  _Environment as environment,
  _getResource as getResource,
  _Logger as logger,
  _Notifications as notifications,
  _Security as security,
  _translate as t,
} from '@/services/core'
import { _StringService as string } from '@/services/core/utils'
import { compile, parse } from 'path-to-regexp'
import qs from 'qs'
import React from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'

/* eslint-disable react/prop-types */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */

export default {
  /**
   * @type {?object} - Hydrated during render
   */
  routes: null,

  /**
   * Get previous uri
   *
   * @return {string}
   */
  get previousUri() {
    return previousUri
  },

  /**
   * Get current uri
   *
   * @return {string}
   */
  get currentUri() {
    return history.location.pathname
  },

  /**
   * Get current search
   *
   * @return {string}
   */
  get currentSearch() {
    return history.location.search
  },

  /**
   * Get location
   *
   * @return {object}
   */
  get location() {
    return history.location
  },

  /**
   * Render router component for routes definition
   *
   * @param {object[]} routes
   *
   * @return {JSX.Element}
   */
  render(routes) {
    if (!this.routes) {
      this.routes = this._build(routes)
      logger.success(
        'Router',
        `${Object.keys(this.routes).length} Routes has been loaded`,
        this.routes,
      )
    }

    return <Router history={history}>{this._renderRoutes(routes)}</Router>
  },

  /**
   * Navigate to url/uri/route
   *
   * @param {string}  routeNameOrPath - path or route name
   * @param {?object} params          - route params if using route name
   */
  navigate(routeNameOrPath, params) {
    history.push(
      routeNameOrPath.indexOf('/') === -1 && this.routes && this.routes[routeNameOrPath]
        ? this.path(routeNameOrPath, params)
        : routeNameOrPath,
    )
  },

  /**
   * Navigate to a specific resource action view
   *
   * @param {string}  resource
   * @param {string}  action
   * @param {?object} params
   */
  navigateToResource(resource, action, params) {
    this.navigate(this.resourcePath(resource, action, params))
  },

  /**
   * Return to previous uri
   */
  goBack() {
    history.back()
  },

  /**
   * Is route is accessible for the current user
   *
   * @param {string} routeName
   *
   * @return boolean
   */
  isAccessible(routeName) {
    const route = this.routes[routeName]

    if (!route) {
      return true
    }

    // Let some routes have a specific behaviour
    if (typeof route.access === 'function') {
      return route.access()
    }

    const resource = route.resource ? getResource(route.resource) : null
    if (resource !== null && route.context) {
      const methodName = `can ${route.context
        .split(':')
        .map((i) => string.ucfirst(i))
        .join('')}`

      if (typeof resource[methodName] === 'function') {
        return resource[methodName]()
      }
    }

    // Compare user with matrix to get authorization
    return security.hasPermissions(route.permissions)
  },

  /**
   * Build path for a route
   *
   * @param {string}  name
   * @param {?object} params - Params of route + query
   *
   * @return {string}
   */
  path(name, params = {}) {
    if (!this.routes || this.routes[name] === undefined) {
      logger.error(
        'Router',
        `You try to build a path of undefined route "${name}" : ${JSON.stringify(params)}`,
        this.routes,
      )
      return '#'
    }

    const { path } = this.routes[name]
    const data = parse(this.routes[name].path)
    const routeParams = []

    for (let i = 0, len = data.length; i < len; ++i) {
      if (data[i].name && !routeParams.includes(data[i].name)) {
        routeParams.push(data[i].name)
      }
    }

    const query = {}
    const queryKeys = Object.keys(params || {})

    for (let i = 0, len = queryKeys.length; i < len; ++i) {
      if (!routeParams.includes(queryKeys[i])) {
        query[queryKeys[i]] = params[queryKeys[i]]
      }
    }

    const suffix = Object.keys(query).length > 0 ? `?${qs.stringify(query)}` : ''
    let result = compile(path, { encode: encodeURIComponent })(params) + suffix

    if (result.indexOf('/') !== 0) {
      result = `/${result}`
    }

    return result
  },

  /**
   * Get path for a resource and a specific context
   *
   * @param {string}  resource
   * @param {string}  context
   * @param {?object} params
   *
   * @return {?string}
   */
  resourcePath(resource, context, params) {
    for (const name in this.routes) {
      if (!this.routes.hasOwnProperty(name)) {
        continue
      }

      if (this.routes[name].resource === resource && this.routes[name].context === context) {
        return this.path(name, params)
      }
    }

    return null
  },

  /**
   * Load routes by normalize
   *
   * @private
   *
   * @param {object[]} routes
   * @param {string}   fromPath     - calc path by prepending this value
   * @param {string}   fromName     - calc name by prepending this value
   * @param {string}   fromResource - Resource context
   *
   * @return {object} - Route data
   */
  _build(routes, fromPath, fromName, fromResource) {
    let data = {}

    for (let i = 0, len = routes.length; i < len; ++i) {
      const path = (fromPath || '') + routes[i].path
      const name = routes[i].name ? (fromName ? `${fromName}_` : '') + routes[i].name : null

      if (name) {
        data[name] = {
          path,
          context: routes[i].context || routes[i].name,
          roles: routes[i].roles,
          permissions: routes[i].permissions,
          access: routes[i].access,
          resource: routes[i].resource || fromResource,
        }
      }

      if (routes[i].routes) {
        data = {
          ...data,
          ...this._build(routes[i].routes, path, name, routes[i].resource || fromResource),
        }
      }
    }

    return data
  },

  /**
   * Render routes collection
   *
   * @private
   *
   * @param {object[]} routes
   * @param {string}   from
   * @param {string}   fromName
   *
   * @return {JSX.Element}
   */
  _renderRoutes(routes, from, fromName = '') {
    return (
      <Switch>
        {routes.map((route) => {
          return this._renderRoute({ from, fromName, ...route })
        })}
      </Switch>
    )
  },

  /**
   * Render route & append in its props the route method
   * to let component renter its nested routes
   *
   * @private
   *
   * @param {object} props
   *
   * @return {JSX.Element}
   */
  _renderRoute({ path, exact, component, render, routes, name, from, fromName, title }) {
    const fullPath = (from || '') + path

    // eslint-disable-next-line no-param-reassign
    name = `${fromName}${fromName ? '_' : ''}${name || ''}`
    const fullName = name

    return (
      <Route
        key={fullPath}
        path={fullPath}
        exact={exact}
        render={({ match, location, ...props }) => {
          // No authenticated on private route
          if (!this.isAccessible(fullName)) {
            notifications.error(t('access_denied'), t('access_denied_description'))

            if (!security.isAuthenticated()) {
              return (
                <Redirect
                  to={{
                    pathname: environment.get('login_path'),
                    state: { from: location || '/' },
                  }}
                />
              )
            }

            // Show view only if its not a nested page
            return (
              (from === undefined || from === '' || from === '/') && (
                <View title={t('access_denied')}>
                  <AccessDenied />
                </View>
              )
            )
          }

          return (
            <View title={title}>
              {React.createElement(component || render, {
                match,
                location,
                ...props,
                routes: () => this._renderRoutes(routes || [], match.url, fullName),
              })}
            </View>
          )
        }}
      />
    )
  },
}
