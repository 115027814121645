import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';

import { uploadRessource } from '@/services/api';
import useCommunityStore from '@/store/community/community.store';

export const useUploadRessource = () => {
  const queryClient = useQueryClient();
  const { communityData } = useCommunityStore();

  return useMutation<
    { success: boolean; message: string },
    AxiosError,
    { formData: FormData; filename: string }
  >({
    mutationFn: (data) => uploadRessource(data.formData, data.filename, communityData!.id),
    onMutate: () => {
      toast.loading('Ajout en cours...', {
        style: {
          padding: '8px',
          color: 'white',
          fontWeight: 'bold',
          fontSize: '14px',
          backgroundColor: communityData?.dataPrivate
            ? communityData?.dataPrivate.primaryColor?.value
            : 'black',
        },
      });
    },
    onSuccess: () => {
      toast.dismiss();
      toast.success("L'ajout de la ressource a réussi", {
        style: {
          padding: '8px',
          color: 'white',
          fontWeight: 'bold',
          fontSize: '14px',
          backgroundColor: communityData?.dataPrivate
            ? communityData?.dataPrivate.primaryColor?.value
            : 'black',
        },
      });
      queryClient.invalidateQueries({ queryKey: ['community'] });
    },
    onError: () => {
      toast.dismiss();
      toast.error("Echec de l'ajout de la ressource");
    },
  });
};
