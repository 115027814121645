import { css } from '@emotion/react';

export const communitiesSelectStyle = (open: boolean) => css`
  padding-top: 40px;

  .MuiAutocomplete-popperDisablePortal {
    z-index: 2000;
  }

  li {
    font-size: 14px;
  }
  .MuiFormControl-root {
    padding-right: ${open ? '20px' : '8px'};
    padding-left: ${open ? '20px' : '8px'};

    .MuiInputBase-root {
      border: none !important;
      background-color: white;
      border-radius: 20px 20px;
      height: 30px;

      .eye-icon {
        position: relative;
        font-size: 18px;
        left: 7px;
        top: ${open ? '-3px' : '0px'};
        z-index: 1;
      }
      .MuiInputBase-input {
        padding-left: 18px !important;
        font-size: 14px;
        position: relative;
        top: -3px;
        display: ${open ? 'initial' : 'none'};
      }
      .MuiOutlinedInput-notchedOutline {
        border: none;
        border-radius: 20px 20px;
      }
      .circular-progress {
        position: relative !important;
        top: ${open ? '-3px' : '-16px'};
        left: ${open ? '25px' : '33px'};
      }
      .MuiAutocomplete-popupIndicator {
        background-color: white;
      }
      .exppand-icon {
        top: 1px;
        position: relative;
      }
    }
  }
`;

export const communityPopperStyle = (open: boolean) => css`
  width: ${open ? '184px !important' : '160px !important'};
  top: ${open ? '5px !important' : '-5px !important'};
  right: ${open ? '22px !important' : '0'};
  left: ${open ? '0' : '12px !important'};
`;
