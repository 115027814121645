import React, { useState } from 'react';
import { Button, Spinner } from 'reactstrap';

import { _Icon as Icon } from '@/components/core/common';
import { CustomTable } from '@/components/core/common/table/custom-table';
import CopilMemberInterface from '@/models/entities/copil-member-interface';
import { _translate as t, _Logger } from '@/services/core';
import CardUser from '@/views/information/project-members/members/card-user';

import useMember, { CopilMembersProps, RowDataProps } from './use-member';

const CopilMembers = ({ data, isReferent = false, onDelete, onUpdate }: CopilMembersProps) => {
  const { rows, columns, handleAdd, memberToEdit, setMemberToEdit } = useMember({
    data,
    isReferent,
    onDelete,
    onUpdate,
  });
  const [isUserCardDisplayed, setUserCardDisplay] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const closeWindow = () => setMemberToEdit(null);

  const handleAddUser = async (form: CopilMemberInterface) => {
    setLoading(true);
    setUserCardDisplay(false);

    if (!form || Object.keys(form).length < 5 || !form.email) {
      setLoading(false);
      _Logger.error('Form missing field', '', { form });
      return;
    }

    await handleAdd(form);
    setLoading(false);
  };

  const handleUpdate = (member: CopilMemberInterface, index: number) => {
    onUpdate(member, index);
    closeWindow();
  };

  return (
    <>
      <CustomTable<RowDataProps> columns={columns} rows={rows} />
      {!isReferent && (
        <>
          <Button
            className='rounded-pill bg-dark-pink border-dark-pink mt-4'
            onClick={() => setUserCardDisplay(!isUserCardDisplayed)}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner size='sm' />
            ) : (
              <>
                <Icon name='plus-circle' className='mr-3' />
                {t('manager_app_add_copil_member')}
              </>
            )}
          </Button>
          {isUserCardDisplayed && (
            <div className='mt-5'>
              <div className='w-100 bg-light px-3 pt-4 pb-2'>
                <CardUser onValidate={handleAddUser} />
              </div>
            </div>
          )}

          {memberToEdit !== null && memberToEdit >= 0 && (
            <>
              <div className='modal-wever-background' role='button' onClick={closeWindow} />
              <div className={`modale-wever d-flex flex-column  align-items-end`}>
                <Button className='bg-transparent text-dark-pink border-none' onClick={closeWindow}>
                  <Icon name='times' />
                </Button>
                <div className='w-100 px-3 pt-4 pb-2'>
                  <CardUser
                    initialState={data[memberToEdit]}
                    submitLabel={t('manager_app_update')}
                    onValidate={(form: CopilMemberInterface) => handleUpdate(form, memberToEdit)}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CopilMembers;
