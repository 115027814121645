/** @jsxImportSource @emotion/react */

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Menu, MenuItem } from '@mui/material';
import { useState } from 'react';

import { RoutesPath } from '@/components/core/common/helpers';
import { headerStyle } from '@/components/layout/user-header/header.style';
import { _Security as security, _translate as t } from '@/services/core';
import router from '@/services/core/router';
import useAuthStore from '@/store/auth/auth.store';
import useCommunityStore from '@/store/community/community.store';

const UserHeader = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { user, setUser } = useAuthStore();
  const { setCommunityData } = useCommunityStore();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMenuItemClick = (action: () => void) => () => {
    action();
    setAnchorEl(null);
  };

  const logout = () => {
    security.deAuthenticate();
    setUser(null);
    setCommunityData(undefined);
    localStorage.clear();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  return (
    <div css={headerStyle}>
      <div aria-describedby={id} onClick={handleClick} className='title-content'>
        <AccountCircleOutlinedIcon fontSize='large' className='account-icon' />
        <div className='user-info'>
          <span>{t('manager_app_my_account')}</span>
          <p className='user-email'>{user?.username}</p>
        </div>
      </div>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClick}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleMenuItemClick(() => router.navigate(RoutesPath.Profile, null))}>
          {t('userapp_account')}
        </MenuItem>
        <MenuItem onClick={handleMenuItemClick(logout)}>
          <span>{t('logout')}</span>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserHeader;
