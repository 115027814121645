import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Language } from '@/models/types';
import { getLanguages } from '@/services/api';

export const useGetLanguages = () => {
  return useQuery<Language[], AxiosError>({
    queryKey: ['languages'],
    queryFn: getLanguages,
  });
};
