/** @jsxImportSource @emotion/react */

import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Stack,
  Tab,
} from '@mui/material';
import { Link } from '@tanstack/react-router';

import Breadcrumbs from '@/components/core/common/breadcrumbs/breadcrumbs';
import NavTabs from '@/components/core/common/nav-tabs/nav-tabs';
import TabPanel from '@/components/core/common/nav-tabs/tab-panel';
import translate from '@/components/core/common/translate';
import LoadingLayout from '@/components/layout/loading-layout';
import { NameTrait } from '@/models/entities/common-types';
import { RoutePath, SearchParams } from '@/models/types';
import { _Icon as Icon, _translate as t } from '@/services/core';
import useCommunityStore from '@/store/community/community.store';
import {
  accordionDetailsStyle,
  accordionSummaryStyle,
  dashboardStyle,
} from '@/views/dashboard/dashboard.style';
import useDynamicHomepage from '@/views/homepage/components/dynamic-homepage/use-dynamic-homepage';

const DynamicHomepage = () => {
  const { homePageData, isLoading } = useDynamicHomepage();
  const { communityData } = useCommunityStore();

  const renderItems = (
    data: {
      name: string;
      path: string;
      id: number;
      subtitle: string;
      categoryName: string;
      menuId: number;
      contentId: number;
    }[],
  ) =>
    data?.map((item, index: number) => (
      <div key={index} css={accordionDetailsStyle}>
        <AccordionDetails className='accordion-details'>
          <Stack direction='row' spacing={1} alignItems='center'>
            <span className='title'>{item.categoryName}</span>
            <span>-</span>
            <span className='title'>
              {typeof item.name === 'object' ? translate(item.name) : t(item.name)}
            </span>
          </Stack>
          <Link
            className='icon-content'
            to={`${item.path}/$id` as RoutePath}
            params={{
              id: item.id,
            }}
            search={(oldSearchParams: SearchParams) => ({
              ...oldSearchParams,
              menu: item.menuId,
              content: item.contentId,
              community: communityData?.id,
            })}
          >
            <VisibilityIcon className='eye-icon' fontSize='small' />
            <span className='subtitle'>{item.subtitle}</span>
          </Link>
        </AccordionDetails>
      </div>
    ));

  const renderSingleItem = (data: {
    name: NameTrait;
    path: string;
    id: number;
    menuId: number;
    contentId: number;
  }) => (
    <div css={accordionDetailsStyle}>
      <AccordionDetails className='accordion-details'>
        <span className='title'>
          {typeof data.name === 'object' ? translate(data.name) : t(data.name)}
        </span>
        <Link
          className='icon-content'
          to={data.path}
          search={(oldSearchParams: SearchParams) => ({
            ...oldSearchParams,
            menu: data.menuId,
            content: data.contentId,
            community: communityData?.id,
          })}
        >
          <VisibilityIcon className='eye-icon' fontSize='small' />
          <span className='subtitle'>{t('manager_app_see_graph')}</span>
        </Link>
      </AccordionDetails>
    </div>
  );

  const renderCollapse = () =>
    homePageData?.map((pageData: any, index: number) => (
      <div key={index} css={accordionSummaryStyle}>
        <Accordion className='accordion-content' disableGutters>
          <AccordionSummary
            className='accordion-summary'
            expandIcon={<Icon name={faAngleDown} className='angle-icon' />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <div className='header-content'>
              <FiberManualRecordIcon fontSize='small' className='circle-icon' />
              <span>{t(pageData.title)}</span>
            </div>
          </AccordionSummary>
          {pageData.type === 'multiData'
            ? renderItems(pageData.data)
            : renderSingleItem(pageData.data)}
        </Accordion>
      </div>
    ));

  const renderNavItems = communityData?.firstMenu?.newContent?.map((menuItem, index) => (
    <Tab key={index} label={translate(menuItem?.name)} />
  ));

  return (
    <div css={dashboardStyle}>
      <Container className='dashboard-container' maxWidth='xl'>
        <Breadcrumbs items={[{ name: communityData?.menu[0].title, url: null }]} />
        <div className='content'>
          <NavTabs value={communityData?.firstMenu?.newContent?.[0]?.id}>{renderNavItems}</NavTabs>
          {isLoading ? (
            <LoadingLayout />
          ) : (
            <TabPanel
              value={communityData?.firstMenu?.newContent?.[0]?.id}
              index={communityData?.firstMenu?.newContent?.[0]?.id}
            >
              <Stack fontSize='18px' fontWeight='bold' paddingTop='10px'>
                {t('manager_app_welcome_od')}
              </Stack>
              <Stack fontSize='18px' paddingTop='15px' paddingBottom='70px'>
                {t('manager_app_choose_category')}
              </Stack>
              {renderCollapse()}
            </TabPanel>
          )}
        </div>
      </Container>
    </div>
  );
};

export default DynamicHomepage;
