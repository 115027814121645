import { ReactComponent as BuidingIcon } from '@/assets/img/icons/common/building-regular.svg';
import { ReactComponent as FolderIcon } from '@/assets/img/icons/common/folderIcon.svg';
import { ReactComponent as Networking } from '@/assets/img/icons/common/networking.svg';

export const menuIcons = [
  { title: 'manager_app_ressources', icon: FolderIcon, displayIcon: true },
  { title: 'manager_app_structures_list', icon: BuidingIcon, displayIcon: true },
  {
    title: 'manager_app_active_mobilities',
    icon: Networking,
    displayIcon: false,
  },
  { title: 'manager_app_enquete_od', icon: Networking, displayIcon: false },
];
