import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AutocompleteRenderOptionState,
  AutocompleteRenderGroupParams,
  CircularProgress,
  PopperProps,
  TextField,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, { ReactNode } from 'react';

interface CustomAutocompleteProps<T> {
  handleChange: (event: React.SyntheticEvent<Element, Event>, newValue: T | null) => void;
  CustomPopper?: (props: PopperProps) => JSX.Element;
  data: T[] | undefined;
  groupBy?: (option: T) => any;
  defaultValue: T | undefined;
  getOptionLabel?: (option: T) => string;
  isLoading: boolean;
  loadingIconSize: string | number;
  startElement?: ReactNode;
  renderOption?:
    | ((
        props: React.HTMLAttributes<HTMLLIElement>,
        option: T,
        state: AutocompleteRenderOptionState,
      ) => React.ReactNode)
    | undefined;
  renderGroup?: (params: AutocompleteRenderGroupParams) => React.ReactNode | undefined;
  size: 'small' | 'medium' | undefined;
  componentRef?: React.MutableRefObject<HTMLDivElement | undefined>;
}

const CustomAutocomplete = <T extends Record<string, unknown>>({
  CustomPopper,
  handleChange,
  data,
  groupBy,
  defaultValue,
  getOptionLabel,
  isLoading,
  loadingIconSize,
  startElement,
  renderOption,
  renderGroup,
  size,
  componentRef,
}: CustomAutocompleteProps<T>) => (
  <Autocomplete
    id='combo-box-demo'
    ref={componentRef}
    options={data ?? []}
    groupBy={groupBy}
    getOptionLabel={getOptionLabel}
    PopperComponent={CustomPopper}
    onChange={handleChange}
    defaultValue={defaultValue}
    renderOption={renderOption}
    disableClearable
    isOptionEqualToValue={(option, value) => option.name === value.name}
    popupIcon={<ExpandMoreIcon className='expand-icon' />}
    renderGroup={renderGroup}
    renderInput={(params) => (
      <TextField
        {...params}
        placeholder='Select'
        size={size}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {isLoading ? (
                <CircularProgress
                  color='inherit'
                  size={loadingIconSize}
                  className='circular-progress'
                />
              ) : (
                params.InputProps.endAdornment
              )}
            </React.Fragment>
          ),
          startAdornment: startElement,
        }}
      />
    )}
  />
);

export default CustomAutocomplete;
