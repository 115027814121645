import { ActionSheetState } from "@/models/entities/common-types";

export type ObjectValues<T> = T[keyof T];
export type LoginResponseData = {
  token: string;
  refresh_token: string;
};

export const USER_ROLES = {
  User: 'ROLE_USER',
  SuperAdmin: 'ROLE_SUPER_ADMIN',
  Admin: 'ROLE_ADMIN',
  AccountManager: 'ROLE_ACCOUNT_MANAGER',
  Manager: 'ROLE_MANAGER',
  Anonymous: 'IS_AUTHENTICATED_ANONYMOUSLY',
  TradDiagnostic: 'ROLE_TRAD_DIAGNOSTIC',
  TeamWever: 'ROLE_TEAM_WEVER',
  Provider: 'ROLE_PROVIDER',
} as const;

export type UserRoles = ObjectValues<typeof USER_ROLES>;

export type User = {
  firstName: string;
  id: number;
  lastName: string;
  locale: string;
  permissions: string[];
  username: string;
  roles: UserRoles;
};

export type Language = {
  enabled: boolean;
  id: number;
  locale: string;
  name: string;
  server: string;
};

export type NameTrait = { [key: string]: string };
export type Categorie = { id: number; name: string; server: string };
export type JourneyType =
  | { id: number; name: NameTrait | undefined; alias: string; tags: Tag[]; filterTags: string[] }
  | undefined;
export type Media = {
  file: {
    contentUrl: string | null;
    description: string | null;
    filePath: string;
    id: number;
    originalName: string;
    path: string;
    server: string;
  };
  id: number;
  mimeType: string | null;
  originalName: string | null;
  server: string;
  webUrl: string | null;
  createdAt: string;
  filePath: string;
  type: string;
};
export type WorkingFieldCategory = { id: number; name: NameTrait; server: string };
export type Dashboard = {
  displayName: boolean;
  id: number;
  journeyTypes: JourneyType;
  name: NameTrait;
  server: string;
};
export type Copil = {
  access: boolean;
  email: string | null;
  firstname: string | null;
  id: number;
  job: string | null;
  lastname: string | null;
  phone: string | null;
  server: string;
};
export type Manager = {
  configuration: Record<string, any>[] | null;
  id: number;
  locale: string;
  server: string;
  username: string;
};
export type Partner = { id: number; partner: ContractPartner; server: string; type: string };
export type ContractPartner = {
  id: number;
  server: string;
  expireAt: string | null;
  key: string;
  name: string;
};
export type Address = {
  address: string | undefined;
  city: string | undefined;
  coordinate: number[];
  country: string | undefined;
  postalCode: string | undefined;
  region: string | undefined;
};
export type Site = {
  name: string | null;
  capacity: number | null;
  address: Address | null;
  duplicate: boolean;
  addressInformationSheet: {
    address: Address | null;
    coordinate: number[] | null;
    country: string | null;
    region: string | null;
    coordinates: Record<string, any>[] | null;
    id: number;
    server: string | null;
    city: string;
  } | null;
};
export type DashboardItem = {
  colspan: number;
  Dashboard: Dashboard | null;
  displayName: boolean;
  graphType: GraphType;
  name: NameTrait;
  parameters: Record<string, any>[] | null;
  parent: string | null;
  server: string;
  text: string | null;
};
export type GraphType = {
  function: string | null;
  translation: { [key: string]: string };
  createdAt: Date;
  updatedAt: Date;
  name: string | null;
  parameters: Record<string, any>[];
  server: string;
};
export type ReportInstanceType = { id: number; name: string | null; server: string };
export type DashboardItemType = { id: number; name: string | null; server: string };
export type Referent = {
  lastname: string | null;
  firstname: string | null;
  job: string | null;
  civility: string | null;
  email: string | null;
  phone: string | null;
};
export type Tag = { names: NameTrait | undefined; alias: string };
export type DataPrivate = {
  primaryColor?: {
    name: string;
    value: string;
  };
  secondaryColor?: {
    name: string;
    value: string;
  };
};

export type MenuConfiguration = {
  id?: number;
  isDynamicHomePage?: boolean;
  homepage?: boolean;
  title: string;
  url?: string;
  tag?: string;
  icon?: string;
  content?: { [key: string]: string[] };
  menu?: MenuConfiguration[];
};

export type ContentItem = {
  id: number;
  name: NameTrait | undefined;
  alias: string;
  filterTags: string[];
  tags: { name: NameTrait | undefined; alias: string }[];
  breadcrumbs?: Breadcrumb[];
};

export type MenuItem = {
  id: number;
  title: string;
  url?: string | null;
  name: NameTrait | string | undefined;
  tag?: string;
  icon?: string;
  parentName: string[];
  newContent?: ContentItem[];
  menu?: MenuItem[];
  breadcrumbs?: Breadcrumb[];
  isDynamicHomePage?: boolean;
  homepage?: boolean;
};

export type Community = {
  createdAt: string;
  id: number;
  url: string | null;
  name: string | null;
  menu: MenuItem[];
  firstMenu: MenuItem;
  accessFields: string[];
  accountType: string | null;
  configuration: any | null;
  copils: Copil[];
  dataPrivate: DataPrivate;
  documentOfficials: string | null;
  files: Media[];
  graphChart: Media[];
  idEmailConnection: string | null;
  information: Record<string, any> | null;
  journeyTypes: JourneyType[];
  key: string | null;
  logo: Media['file'] | null;
  logoVector: string | null;
  managers: Manager[];
  parent: Community;
  partners: Partner[];
  public: boolean;
  referent: string;
  report: string;
  server: string;
  structureType: { [locale: string]: string } | null;
  serverDefault: string;
  sheetActions: {
    actions: Record<string, any>[] | null;
    endDate: string | null;
    id: number;
    name: string;
    server: string;
    sheetAction: string;
    state: ActionSheetState | null;
    startDate: string | null;
  }[];
  sites: Site[];
  mediaPrivates: Media[];
  tags: Tag[];
};

export interface ManagedCommunity {
  files: Media[];
  graphChart: Media[];
  id: number;
  key: string | null;
  logo: string | null;
  logoVector: string | null;
  maps?: Map[];
  dashboards?: Dashboard[];
  name: string;
  parent: ManagedCommunity;
  public: boolean;
  referent: string | null;
  report: string | null;
  server: string;
  url: string | null;
}

export type Map = {
  id: number;
  name: NameTrait;
  journeyType: JourneyType[];
  workingFieldCategory: WorkingFieldCategory;
  server: string;
  community: Community;
  creator: Record<string, any> | null;
  instanceType: ReportInstanceType;
  layers: Record<string, any> | null;
  tags: string[] | undefined;
};

export type SearchParams = {
  community?: number;
  menu?: number;
  content?: number;
  structureStatus?: StructureValidationState;
  stateMode?: string;
};

export type Breadcrumb = {
  name: string | NameTrait | undefined;
  url: string | null;
};

export type DashboardLayoutType = {
  id: number;
  name: NameTrait;
  journeyType: string;
  workingFieldCategory: WorkingFieldCategory;
  items: DashboardItem[];
  pattern: boolean;
  reportInstanceType: ReportInstanceType;
  server: string;
  type: DashboardItemType;
  tags: string[] | undefined;
};

export type ActionSheetsType = {
  id: number;
  name: string | null;
  categories: Categorie[];
  state: ActionSheetState | null;
  costs: Record<string, any>[] | null;
  description: string | null;
  durations: Record<string, any>[] | null;
  evaluationCriteria: Record<string, any>[] | null;
  journeyType: string;
  objectives: Record<string, any>[] | null;
  medias: Media[] | null;
  validate: boolean;
  community: Community;
  workingFieldCategory: WorkingFieldCategory;
  evaluationDashboard: Dashboard | null;
  feedbackDashboard: Dashboard | null;
  dimensionDashboard: Dashboard | null;
  durationAcceptation: number | null;
  actions: Record<string, any>[] | null;
  presentation: string | null;
  tags: string[] | undefined;
};

export type CommunityActionSheets = {
  id: number;
  name: string | null;
  logo: null;
  sheetActions: { sheetAction: string }[];
};

export type StructureReferencement = {
  id: number;
  structure: { name: NameTrait; alias: string | null };
  activity: { name: NameTrait; alias: string | null };
  community: Community;
  name: string | null;
  sites: Site[];
  logo: Media;
  referent: Referent;
  siret: string | null;
  status: string | null;
  state: StructureValidationState | null;
};

export const StructureValidationStateEnum = {
  accepted: 'accepted',
  waiting: 'waiting',
  canceled: 'canceled',
} as const;

export type StructureValidationState =
  (typeof StructureValidationStateEnum)[keyof typeof StructureValidationStateEnum];

export type ConfigResponse = {
  config?: number | null;
};

export type MainInformationConfigQuestion = {
  _id: number;
  alias: string;
  name: string;
  responses: { alias: string; value: string | null; name: string }[];
  communityResponses: { value: boolean | string; alias: string; name: string | null }[] | null;
  type: string;
  parameters?: {
    isMulti?: boolean;
    options?: { label: string; value: string }[];
    isRequired?: boolean;
    label?: string;
  };
};

export type MainInformationConfig = {
  name: string;
  questions: MainInformationConfigQuestion[];
};

export type MainInformationConfigWithDiagnosticId = {
  diagnosticId: number;
  config: MainInformationConfig[];
};

export type GraphInterface = {
  id: number;
  name: NameTrait;
  journeyType: string;
  workingFieldCategory: WorkingFieldCategory;
  items: DashboardItem[];
  pattern: boolean;
  reportInstanceType: ReportInstanceType;
  server: string;
  type: DashboardItemType;
  tags: string[] | undefined;
};

export type ActionsSheetInterface = {
  id: number;
  name: string | null;
  categories: Categorie[];
  costs: Record<string, any>[] | null;
  description: string | null;
  durations: Record<string, any>[] | null;
  evaluationCriteria: Record<string, any>[] | null;
  journeyType: string;
  objectives: Record<string, any>[] | null;
  medias: Media[] | null;
  validate: boolean;
  community: Community;
  workingFieldCategory: WorkingFieldCategory;
  evaluationDashboard: Dashboard | null;
  feedbackDashboard: Dashboard | null;
  dimensionDashboard: Dashboard | null;
  durationAcceptation: number | null;
  actions: Record<string, any>[] | null;
  presentation: string | null;
  tags: string[] | undefined;
};

export type CommunityActionsSheetInterface = {
  id: number;
  name: string | null;
  logo: null;
  sheetActions: { sheetAction: string }[];
};

export type CopilMemberInterface = {
  fullName?: string;
  lastname?: string;
  firstname?: string;
  job?: string;
  phone?: string;
  email?: string;
  referent: boolean;
};

const RoutePaths = {
  login: '/login',
  home: '/',
  maps: '/maps',
  graphs: '/graphs',
  actionSheets: '/action-sheets',
  ressources: '/ressources',
  structureList: '/structure-list',
  information: '/information',
  projectMembers: '/project-members',
  mapsById: '/maps/$id',
  graphsById: '/graphs/$id',
  actionSheetsById: '/action-sheets/$id',
  homepage: '/homepage',
} as const;

export type RoutePath = (typeof RoutePaths)[keyof typeof RoutePaths];

export type CommunityChildren = {
  id: number;
  name: string;
  createdAt: string;
  site: Community['sites'][0];
  totalCapacity: number;
  diagnostics: number[];
  acceptedActionSheets: {tag:string, actionSheets: {id: string, name: string}[]}[]
  participation: string;
};
