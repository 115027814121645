import { Outlet, createRouter, createRootRoute, createRoute } from '@tanstack/react-router';

import Layout from '@/components/layout/layout';
import PrivateRoute from '@/routes/PrivateRoute';
import RedirectHomepage from '@/routes/RedirectHomepage';
import ActionSheets from '@/views/action-sheets/actions-sheets';
import SheetAction from '@/views/action-sheets/single-action-sheet/sheet-action';
import DashboardAnalytics from '@/views/analytics/dashboard/dashboard';
import ListDashboardAnalytics from '@/views/analytics/list-dashboard';
import Graphs from '@/views/graphs/graphs';
import Graph from '@/views/graphs/single-graph/graph';
import HomePage from '@/views/homepage/home-page';
import MainInformation from '@/views/information/main/main-information';
import ProjectMembers from '@/views/information/project-members/project-members';
import LoginView from '@/views/login/login-view';
import Poster from '@/views/poster/poster';
import Ressources from '@/views/ressources/ressources';
import StructureList from '@/views/structure-list/structure-list';

const rootRoute = createRootRoute({
  component: () => (
    <>
      <Outlet />
    </>
  ),
});

const loginRoute = createRoute({
  path: '/login',
  getParentRoute: () => rootRoute,
  component: LoginView,
});

const homeRoute = createRoute({
  path: '/',
  getParentRoute: () => rootRoute,
  component: () => {
    return (
      <PrivateRoute>
        <Layout />
      </PrivateRoute>
    );
  },
});

const mapsRoute = createRoute({
  path: '/maps',
  getParentRoute: () => homeRoute,
  component: Graphs,
});

const graphsRoute = createRoute({
  path: '/graphs',
  getParentRoute: () => homeRoute,
  component: Graphs,
});

const actionSheetsRoute = createRoute({
  path: '/action-sheets',
  getParentRoute: () => homeRoute,
  component: ActionSheets,
});

const ressourcesRoute = createRoute({
  path: '/ressources',
  getParentRoute: () => homeRoute,
  component: Ressources,
});

const structureRoute = createRoute({
  path: '/structure-list',
  getParentRoute: () => homeRoute,
  component: StructureList,
});

const informationRoute = createRoute({
  path: '/information',
  getParentRoute: () => homeRoute,
  component: MainInformation,
});

const membersRoute = createRoute({
  path: '/project-members',
  getParentRoute: () => homeRoute,
  component: ProjectMembers,
});

export const mapsByIdRoute = createRoute({
  path: '/maps/$id',
  getParentRoute: () => homeRoute,
  component: Graph,
});

const graphByIdRoute = createRoute({
  path: '/graphs/$id',
  getParentRoute: () => homeRoute,
  component: Graph,
});

export const actionSheetsByIdRoute = createRoute({
  path: '/action-sheets/$id',
  getParentRoute: () => homeRoute,
  component: SheetAction,
});

export const homepageRoute = createRoute({
  path: '/homepage',
  getParentRoute: () => homeRoute,
  component: () => (
    <RedirectHomepage>
      <HomePage />
    </RedirectHomepage>
  ),
});

export const analyticsRoute = createRoute({
  path: '/analytics',
  getParentRoute: () => homeRoute,
  component: ListDashboardAnalytics,
});
export const analyticsItemsRoute = createRoute({
  path: '/analytics/$id',
  getParentRoute: () => homeRoute,
  component: DashboardAnalytics,
});

export const posterRoute = createRoute({
  path: '/poster',
  getParentRoute: () => homeRoute,
  component: Poster,
});

const routeTree = rootRoute.addChildren([
  loginRoute,
  homeRoute.addChildren([
    mapsRoute,
    graphsRoute,
    actionSheetsRoute,
    ressourcesRoute,
    structureRoute,
    informationRoute,
    membersRoute,
    mapsByIdRoute,
    graphByIdRoute,
    actionSheetsByIdRoute,
    homepageRoute,
    analyticsRoute,
    analyticsItemsRoute,
    posterRoute,
  ]),
]);

export const router = createRouter({ routeTree, defaultPreload: 'intent' });

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}
