import { List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import React from 'react';
import { FaRegTrashCan } from 'react-icons/fa6';

import EditTextPopper from '@/views/poster/components/edit-text-popper';
import { TextItem } from '@/views/poster/components/url-image';

interface TextItemEditorProps {
  texts: TextItem[];
  updateTextColor: (id: string, color: string) => void;
  updateTextContent: (id: string, content: string) => void;
  updateTextFontSize: (id: string, size: number) => void;
  updateTextFontWeight: (id: string, weight: string) => void;
  deleteText: (id: string) => void;
}

const TextItemEditor: React.FC<TextItemEditorProps> = ({
  texts,
  updateTextColor,
  updateTextContent,
  updateTextFontSize,
  updateTextFontWeight,
  deleteText,
}) => {
  const [activePopperId, setActivePopperId] = React.useState<string | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClickText = (id: string, event: React.MouseEvent<HTMLElement>) => {
    if (activePopperId === id) {
      setActivePopperId(null);
      setAnchorEl(null);
    } else {
      setActivePopperId(id);
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    texts.length > 0 && (
      <div style={{ paddingTop: '10px', paddingLeft: '15px' }}>
        <Stack component={Typography} variant='h6'>
          Textes
        </Stack>
        <List dense>
          {texts.map((textItem) => (
            <React.Fragment key={textItem.id}>
              <ListItem>
                <ListItemText
                  sx={{ cursor: 'pointer' }}
                  onClick={(event) => handleClickText(textItem.id, event)}
                  primary={textItem.id}
                />
                <ListItemIcon sx={{ cursor: 'pointer' }} onClick={() => deleteText(textItem.id)}>
                  <FaRegTrashCan />
                </ListItemIcon>
              </ListItem>
              <EditTextPopper
                mode='edit'
                textItem={textItem}
                anchorEl={activePopperId === textItem.id ? anchorEl : null}
                setAnchorEl={(el) => {
                  if (el) {
                    setActivePopperId(textItem.id);
                    setAnchorEl(el);
                  } else {
                    setActivePopperId(null);
                    setAnchorEl(null);
                  }
                }}
                updateTextColor={updateTextColor}
                updateTextContent={updateTextContent}
                updateTextFontSize={updateTextFontSize}
                updateTextFontWeight={updateTextFontWeight}
              />
            </React.Fragment>
          ))}
        </List>
      </div>
    )
  );
};

export default TextItemEditor;
